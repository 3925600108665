import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper/modules";

import "swiper/css";

import img1 from "../../../assets/img/esg/esgSeven/card1.png";
import img2 from "../../../assets/img/esg/esgSeven/card2.png";
import img3 from "../../../assets/img/esg/esgSeven/card3.png";
import img4 from "../../../assets/img/esg/esgSeven/card4.png";
import img5 from "../../../assets/img/esg/esgSeven/card5.png";
import img6 from "../../../assets/img/esg/esgSeven/card6.png";
import img7 from "../../../assets/img/esg/esgSeven/card7.png";
import img8 from "../../../assets/img/esg/esgSeven/card8.png";

function EsgSeven() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const breakpoints = {
    1200: {
      slidesPerView: 4,
    },
    1000: {
      spaceBetween: 20,
    },
    900: {
      slidesPerView: 3,
    },
    700: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div id="esgSeven" className={`esgSeven ${isVisible ? "visible" : ""}`}>
        <div className="esgSevenTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Nosso compromisso com a comunidade{" "}
            <span>vai além das estradas</span>
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Reconhecemos a influência significativa que grandes empresas podem
            exercer em questões sociais e ambientais. Por isso, mantemos um
            constante envolvimento em iniciativas voltadas para a vida e o
            planeta.
          </p>
        </div>
        <Swiper
          slidesPerView={4}
          spaceBetween={40}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={500}
          loop={true}
          modules={[Autoplay]}
          breakpoints={breakpoints}
          className={`${isVisible ? "visible" : ""}`}
        >
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img1} alt="" />
              <p>
                Anualmente, promovemos uma campanha interna para arrecadar
                caixas de chocolate entre nossos colaboradores, com a GH
                igualando o valor arrecadado para dobrar a doação. As doações
                são destinadas às instituições parceiras, que monta cestas e as
                distribui às crianças atendidas. Essa iniciativa busca levar
                alegria e solidariedade durante a Páscoa.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img2} alt="" />
              <p>
                No Dia das Crianças, a GH realiza a doação de brinquedos
                produzidos pelo programa interno "Resinas que Transformam,"
                parte do Programa Pellets Zero, em parceria com diversas
                instituições. Essa iniciativa visa levar alegria às crianças e
                promover a conscientização sobre a reciclagem de materiais.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img3} alt="" />
              <p>
                Anualmente, as instituições parceiras realizam uma ação onde as
                crianças escrevem cartinhas para o Papai Noel, e a GH participa
                recolhendo essas cartas. Os colaboradores adotam uma carta e
                presenteiam a criança com o que foi pedido. Os presentes são
                entregues durante uma grande festa de Natal organizada
                especialmente para elas.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img4} alt="" />
              <p>
                O propósito desta iniciativa é estimular a arrecadação interna
                de roupas e artigos de inverno entre os colaboradores, com o
                objetivo de doar esses itens a famílias em situação de
                vulnerabilidade social. A ação visa proporcionar conforto e
                apoio durante os meses mais frios.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img5} alt="" />
              <p>
                O Programa Atuação Responsável, uma marca registrada da Abiquim
                – Associação Brasileira da Indústria Química, representa um
                compromisso essencial da indústria química global e brasileira
                com a excelência em saúde, segurança e meio ambiente. Seu
                propósito fundamental é mostrar de forma clara e voluntária o
                comprometimento do setor em aprimorar continuamente seus padrões
                nessas áreas-chave.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img6} alt="" />
              <p>
                É nosso dever proteger as crianças e adolescentes da exploração 
                sexual nas rodovias brasileiras, por isso fazemos parte das empresas
                que se comprometem com o Programa Na Mão Certa da Childhood Brasil, 
                para enfrentar essa grave violação de direitos humanos.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img7} alt="" />
              <p>
                Em prol do fim dos acidentes com produtos químicos no transporte rodoviário, 
                o Programa Olho Vivo na Estrada tem o objetivo de prevenir atitudes inseguras
                no transporte de produtos perigosos e nós apoiamos essa causa por meio da 
                conscientização dos motoristas.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img8} alt="" />
              <p>
                A conscientização no trânsito deve ser feita todos os meses do ano, para reduzir 
                as mortes causadas por acidentes ao volante, por isso, nós ajudamos dilvugando a 
                campanha em nosso caminhão temático por todo o país e apoiamos a causa todos os dias.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}

export default EsgSeven;
