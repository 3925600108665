import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/esg/esgOne/img1.webp";
import img2 from "../../../assets/img/esg/esgOne/img2.webp";
import img3 from "../../../assets/img/esg/esgOne/img3.webp";
import img4 from "../../../assets/img/esg/esgOne/img4.webp";

function EsgOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div id="esgOne" className={`esgOne ${isVisible ? "visible" : ""}`}>
        <div className="esgOneTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Programa Mudando Histórias
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Em 2023, ampliamos o escopo do programa oficial da GH ao incluir o
            programa Mudando Histórias sob o guarda-chuva de responsabilidade do
            Sinerghia Social. Esta ação direta tem como objetivo primordial
            combater a fome no Brasil. Atualmente, estamos alocando recursos
            para a doação mensal de 350 pratos de marmitas nas cidades de São
            Paulo e Itajaí em conjunto com a secretaria municipal e CRAS.
          </p>
        </div>
        <div className={`img1 ${isVisible ? "visible" : ""}`}>
          <img src={img1} alt="" />
        </div>
        <div className={`img2 ${isVisible ? "visible" : ""}`}>
          <img src={img2} alt="" />
        </div>
        <div className={`img3 ${isVisible ? "visible" : ""}`}>
          <img src={img3} alt="" />
        </div>
        <div className={`img4 ${isVisible ? "visible" : ""}`}>
          <img src={img4} alt="" />
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default EsgOne;
