import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useState } from "react";
import postServices from '../../../services/post'
import { useNavigate } from "react-router-dom";

const BlogContext = createContext()

export const useBlogContext = () => useContext(BlogContext)

export const BlogProvider = ({ children }) => {

    const [titleToSearch, setTitleToSearch] = useState("")
    const [postToShow, setPostToShow] = useState(null)

    const navigate = useNavigate()

    const handleNavigateToVisiblePubli = post => {
        setPostToShow(post)
        navigate("/blog/visiblePubli")
    }

    const { data: allPosts, isPending: isFetchingAllPosts } = useQuery({
        queryKey: ['carrosel', titleToSearch],
        queryFn: () => postServices.getAllPosts(titleToSearch),
        stale: 18000000, //5h,
        refetchOnWindowFocus: false
      })
    

    const contextValues = {
        titleToSearch,
        setTitleToSearch,
        setPostToShow,
        postToShow,
        handleNavigateToVisiblePubli,
        isFetchingAllPosts,
        allPosts
    }

    return (
        <BlogContext.Provider value={contextValues}>
            {children}
        </BlogContext.Provider>
    )
}
