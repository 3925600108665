import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";


function FleetOne() {

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={500}>

    <div id="Fleet-structure-truck">
      <div className="container">
        <h1 className={`${isVisible ? "visible" : ""}`}>
          <span>Uma frota sólida</span> para impulsionar seus <br></br> negócios
          rumo a novos horizontes
        </h1>
        <p className={`mt-4 ${isVisible ? "visible" : ""}`}>
          Comprometemo-nos incessantemente com o aprimoramento contínuo do transporte e da gestão de cargas. 
          Investimos em tecnologias de ponta e soluções inovadoras para garantir serviços de qualidade e segurança 
          incomparáveis no transporte rodoviário e na logística de mercadorias. Nosso objetivo é impulsionar seus negócios 
          para novos horizontes, oferecendo uma frota sólida e confiável.
        </p>
      </div>
      <div class="sketchfab-embed-wrapper">
        <iframe
          className={`${isVisible ? "visible" : ""}`}
          title="GH Solucionador Logístico"
          frameborder="0"
          allowfullscreen
          mozallowfullscreen="true"s
          webkitallowfullscreen="true"
          allow="autoplay; fullscreen; xr-spatial-tracking"
          xr-spatial-tracking
          execution-while-out-of-viewport
          execution-while-not-rendered
          web-share
          src="https://sketchfab.com/models/d7f73afd3d6e4d0d9ceb8b6dbc1bfcd2/embed?autostart=1"
          style={{
            width: "100%",
            height: "550px",
          }}
        ></iframe>
      </div>
    </div>
    </VisibilitySensor>
  );
}

export default FleetOne;
