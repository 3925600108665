import React, { useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import {
  CNavbar,
  CButton,
  CForm,
  CFormInput,
  CInputGroupText,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilSearch } from "@coreui/icons";
import { useBlogContext } from "../contexts/BlogContext";

function SubNavBlog() {
  const { setTitleToSearch } = useBlogContext();

  const postTitleInputRef = useRef();

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility={true}>
      <div id="sub-nav">
        <CNavbar colorScheme="light">
          <CForm>
            <div className={`d-flex ms-4 search ${isVisible ? "visible" : ""}`}>
              <CFormInput
                type="search"
                className="me-2"
                placeholder="Pesquisar"
                ref={postTitleInputRef}
              />
              <CInputGroupText
                title="buscar post por título"
                role="button"
                id="basic-addon1"
                onClick={() => {
                  setTitleToSearch(postTitleInputRef.current.value);
                }}
              >
                <CIcon icon={cilSearch} width={20} />
              </CInputGroupText>
            </div>
            <a
              className={`blogBtn ${isVisible ? "visible" : ""}`}
              href="https://blog.ghlogistica.com.br/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CButton>Entrar</CButton>
            </a>
          </CForm>
        </CNavbar>
      </div>
    </VisibilitySensor>
  );
}

export default SubNavBlog;
