import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/fleetStructure/fleetTwo/fleetTwoImg.webp";

function FleetTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={500}>
      <div className="fleetTwo">
        <div className="fleetTwoContent">
          <div className="fleetTwoTitle">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              A média de idade de <br /> nossa frota
            </h1>
          </div>
          <div className="fleetTwoText">
            <div className="text1 text">
              <h3 className={`${isVisible ? "visible" : ""}`}>2,7 anos</h3>
              <p className={`${isVisible ? "visible" : ""}`}>
                Média total dos cavalos
              </p>
            </div>
            <div className="text2 text">
              <h3 className={`${isVisible ? "visible" : ""}`}>3,2 anos</h3>
              <p className={`${isVisible ? "visible" : ""}`}>
                Média total das carretas
              </p>
            </div>
            <p className={`${isVisible ? "visible" : ""}`}>
              Na GH, orgulhamo-nos de nossa frota moderna e bem-mantida,
              garantindo operações logísticas eficientes e confiáveis. Com uma
              idade média da frota de cavalos de 2,7 anos e carretas de 3,2
              anos, estamos comprometidos em fornecer serviços de transporte de
              alto nível, com segurança e pontualidade.
            </p>
          </div>
        </div>
        <div className={`fleetTwoImg ${isVisible ? "visible" : ""}`}>
          <img src={img1} alt="caminhão azul da GH" width={"900px"} />
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default FleetTwo;
