import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import truck1 from "../../../assets/img/esg/esgFour/truck1.webp";
import gheco from "../../../assets/img/fleetStructure/fleetFour/GHECO DRIVING.png";
import seta from "../../../assets/img/components/seta.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";
import arrowRight from "../../../assets/img/components/arrow-right.webp";

import "swiper/css";
import "swiper/css/navigation";

const data = [
  {
    id: 1,
    title: "GNV (Gás Natural Veicular)",
    class: "twoText",
    content1: [
      "A GH, buscando soluções sustentáveis, dobrou a capacidade de armazenamento de seus veículos, aumentando a autonomia em 50%, o que permite viagens mais longas sem abastecimento.",
    ],
    content2: [
      "Isso impulsiona a empresa como líder no uso de caminhões movidos a GNV em rotas de longa distância, promovendo uma abordagem mais sustentável e econômica no transporte.",
    ],
    imageUrl: truck1,
  },
  {
    id: 2,
    title: "GHeco Driving",
    class: "oneText gheco",
    content1: [
      "A GH implementa diversas ações que impactam o controle do efeito estufa, destacando-se o programa de bonificação GHEco Driving. Este programa incentiva motoristas a adotarem práticas de condução segura e sustentável, focando na redução das emissões de CO2. Através do GHEco Driving, motoristas são reconhecidos e recompensados por comportamentos que promovem a segurança no trânsito e diminuem o impacto ambiental, incentivando uma condução mais eficiente e ecológica.",
    ],
    imageUrl: gheco,
  },
  {
    id: 4,
    class: "twoText",
    title: "Câmera Frontal e Sistema de Detecção de Fadiga",
    content1: [
      "Dispositivo de monitoramento que grava todo o trajeto, detectando riscos como distância inadequada entre veículos e trocas de faixa repentinas. Além disso, incorpora um sistema que identifica sinais de fadiga no motorista e emite alertas automáticos para aumentar a segurança no trânsito.",
    ],
    content2: [
      "Câmera de segurança que grava continuamente o percurso, auxiliando na prevenção de acessos não autorizados à carga e na análise de causas de acidentes.",
    ],
    //imageUrl: img3,
  },
  {
    id: 5,
    class: "oneText",
    title: "Rastreador Satelital SASSCAR e OnixSat",
    content1: [
      "Rastreador em tempo real via satélite, proporcionando gerenciamento de risco aprimorado e garantindo segurança para o motorista, o veículo e a carga. ",
    ],
    //imageUrl: img3,
  },
  {
    id: 6,
    class: "oneText",
    title: "Sistema de Telemetria Veicular",
    content1: [
      "Sistema que monitora o consumo de combustível para otimizar a eficiência do veículo, bem como analisa o estilo de condução do motorista. Esses dados são utilizados para premiar motoristas que dirigem de forma mais sustentável e segura, incentivando práticas de condução responsáveis.",
    ],
    //imageUrl: img3,
  },
  {
    id: 7,
    class: "oneText",
    title: "Dispositivo Anti-Jammer",
    content1: [
      "Dispositivo que contribui para a segurança do veículo, bloqueando ou dificultando a ação de dispositivos que tentam interromper ou interferir no sinal do rastreador, reduzindo o risco de furtos e roubos.",
    ],
    //imageUrl: img3,
  },
  {
    id: 8,
    class: "oneText",
    title: "Rastreador via Satélite",
    content1: [
      "Rastreador que utiliza satélite para informar a localização da carreta em tempo real, aumentando a segurança do veículo e da carga e auxiliando no gerenciamento de risco.",
    ],
    //imageUrl: img3,
  },
  {
    id: 9,
    class: "oneText",
    title: "Gestão de Pneus",
    content1: [
      "Utilização de pneus com baixa resistência ao rolamento para aumentar a eficiência energética do veículo, ao mesmo tempo em que monitora a profundidade dos sulcos e a quilometragem para prolongar a vida útil dos pneus.",
    ],
    //imageUrl: img3,
  },
  {
    id: 10,
    class: "oneText",
    title: "Defletores Aerodinâmicos",
    content1: [
      "Todos os cavalos mecânicos são equipados com defletores para melhorar a aerodinâmica, reduzindo a resistência do ar e aumentando a eficiência no deslocamento, resultando em menor consumo de combustível.",
    ],
    //imageUrl: img3,
  },
  {
    id: 11,
    class: "oneText",
    title: "Trava de Quinta Roda",
    content1: [
      "Sistema de travamento projetado para evitar desengates não autorizados da quinta roda, proporcionando segurança adicional para caminhões e carretas.",
    ],
    //imageUrl: img3,
  },
];

function FleetFour() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div className={`fleetFour ${isVisible ? "visible" : ""}`}>
        <div className="fleetFourTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Tecnologia Sustentável : <span> Inovações em Veículos</span>
          </h1>
        </div>
        <Swiper
          className={`${isVisible ? "visible" : ""}`}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Navigation]}
          speed={800}
          slidesPerView={"1"}
          spaceBetween={200}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <div className={`swiper-button-prev`}>
            <img src={arrowLeft} width={"40px"} alt="" />
          </div>
          {data.map((item) => (
            <SwiperSlide key={item.id}>
              <div className={`card ${item.class}`}>
                <div className="cardText">
                  <h3 className={`${isVisible ? "visible" : ""}`}>
                    {item.title}
                  </h3>
                  <p className={`text1 ${isVisible ? "visible" : ""}`}>
                    <img src={seta} className="seta" alt="" />
                    {item.content1}
                  </p>
                  <p className={`text2 ${isVisible ? "visible" : ""}`}>
                    <img src={seta} className="seta" alt="" />
                    {item.content2}
                  </p>
                  <p className={`text3 ${isVisible ? "visible" : ""}`}>
                    <img src={seta} className="seta" alt="" />
                    {item.content3}
                  </p>
                  <p className={`text4 ${isVisible ? "visible" : ""}`}>
                    <img src={seta} className="seta" alt="" />
                    {item.content4}
                  </p>
                </div>
                <img
                  className={`${isVisible ? "visible" : ""}`}
                  src={item.imageUrl}
                  alt=""
                  width={"600px"}
                />
              </div>
            </SwiperSlide>
          ))}
          <div className={`swiper-button-next`}>
            <img src={arrowRight} width={"40px"} alt="" />
          </div>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}
export default FleetFour;
