import React, { useState } from "react";
import { CButton, CForm, CFormInput, CFormLabel, CFormTextarea } from "@coreui/react-pro";

import VisibilitySensor from "react-visibility-sensor";

import email from "../../../assets/img/contact/contactOne/email.webp";
import phone from "../../../assets/img/contact/contactOne/phone.webp";
import whats from "../../../assets/img/contact/contactOne/whatsapp.webp";
import { sendContactEmail } from "../../../services/sendContactEmail";
import { useAlert } from "../../../contexts/AlertContext";

function ContactOne() {

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const defaultFormData = {
    name: '',
    enterprise: '',
    email: '',
    phone: '',
    message: '',
    setor: '',


    isLoadingResponse: false
  }

  const [formData, setFormData] = useState(defaultFormData)

  const formatPhoneValue = (event) => {
    const formattedPhone = event.target.value
      .replace(/\D/g, '') // Remove todos os caracteres não numéricos
      .replace(/^(\d{2})(\d)/g, '($1) $2') // Adiciona parênteses em volta dos dois primeiros dígitos
      .replace(/(\d)(\d{4})$/, '$1-$2'); // Adiciona hífen entre o quinto e o sexto dígitos

    setFormData(prev => ({ ...prev, phone: formattedPhone }))
  }

  const {showAlert} = useAlert()


  const handleSubmit = async () => {

    setFormData(prev => ({ ...prev, isLoadingResponse: true }))


    const response = await sendContactEmail(formData)

    if (response.success) {
      setFormData(defaultFormData)
    }

    showAlert(response.message, response.success ? 'success' : 'error')
    setFormData(prev => ({ ...prev, isLoadingResponse: false }))
  }


  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className={`contactOne ${isVisible ? 'visible' : ''}`}>
        <div className="contactOneText">
          <h1 className={`${isVisible ? 'visible' : ''}`}>Entre em contato <span>conosco</span></h1>
          <p className={`${isVisible ? 'visible' : ''}`}>Estamos disponíveis a qualquer horário, por isso não hesite em nos contatar. Será um prazer atendê-lo(a).</p>
          <p className={`address ${isVisible ? 'visible' : ''}`}>R. Francisco Reis, 1402 - Cordeiros, Itajaí - SC, 88311-750</p>
          <div className="contacts">
            <div className={`email ${isVisible ? 'visible' : ''}`}>
              <img src={email} alt="" width={'20px'} height={'15px'} />
              <p>contato@ghtransporte.com.br</p>
            </div>
            <div className={`phone ${isVisible ? 'visible' : ''}`}>
              <img src={phone} alt="" width={'20px'} />
              <p>(47) 3045-1700 </p>
            </div>
            <div className={`phone phone2 ${isVisible ? 'visible' : ''}`}>
              <img src={whats} alt="" width={'20px'}/>
              <p>(47) 99220-4104</p>
            </div>
          </div>
        </div>
        <CForm className={`${isVisible ? 'visible' : ''}`}>
          <div>
            <CFormLabel className="me-2 labelName">Nome:</CFormLabel>
            <CFormInput
              type="text"
              maxLength={80}
              minLength={3}
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="me-2 inputName"
              placeholder=""
            ></CFormInput>
            <CFormLabel>Empresa:</CFormLabel>
            <CFormInput
              type="text"
              autoComplete='off'
              maxLength={40}
              className="me-2"
              placeholder=""
              value={formData.enterprise}
              onChange={(e) => setFormData(prev => ({ ...prev, enterprise: e.target.value }))}
            ></CFormInput>
            <CFormLabel>E-mail:</CFormLabel>
            <CFormInput
              type="email"
              autoComplete='off'
              maxLength={60}
              className="me-2"
              placeholder=""
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
            ></CFormInput>
            <CFormLabel className="mt-4">Telefone:</CFormLabel>
            <CFormInput
              type="phone"
              autoComplete='off'
              maxLength={15}
              minLength={15}
              onChange={formatPhoneValue}
              className="me-2"
              placeholder=""
              value={formData.phone}
            ></CFormInput>
            <CFormLabel className="mt-4">Setor:</CFormLabel>
            <CFormInput
              type="text"
              className="me-2"
              placeholder=""
              value={formData.setor}
              onChange={(e) => setFormData(prev => ({ ...prev, setor: e.target.value }))}
            />
            <CFormLabel className="mt-4">Mensagem:</CFormLabel>
            <CFormTextarea
              type="text"
              autoComplete='off'
              maxLength={300}
              minLength={25}
              rows={4}
              as="text-area"
              className="me-2 message"
              placeholder=""
              value={formData.message}
              onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
            ></CFormTextarea>
            <CButton className="mt-4" onClick={handleSubmit} disabled={formData.isLoadingResponse}>
              {formData.isLoadingResponse ? 'Enviando...' : 'Enviar'}
            </CButton>
          </div>
        </CForm>
      </div>
    </VisibilitySensor>
  )
}
export default ContactOne;