import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/home/career/img2.webp";

function WarehouseTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={500}>
      <div className={`warehouseTwo ${isVisible ? "visible" : ""}`}>
        <div className="arrows1">
          <img
            className={`img1 ${isVisible ? "visible" : ""}`}
            src={img1}
            alt=""
            width={"160px"}
          />
          <img
            className={`img2 ${isVisible ? "visible" : ""}`}
            src={img1}
            alt=""
            width={"160px"}
          />
        </div>
        <h1 className={`${isVisible ? "visible" : ""}`}>
          O coração da nossa operação.
        </h1>
        <p className={`${isVisible ? "visible" : ""}`}>
          A GH possui armazéns que são o coração pulsante da nossa operação
          logística. Equipado com tecnologia de ponta e cuidadosamente
          gerenciado por uma equipe dedicada, nosso armazém é o lugar onde a
          eficiência se encontra com a segurança. Aqui, sua carga é tratada com
          o mais alto padrão de cuidado, garantindo que ela esteja sempre pronta
          para seguir em frente, rumo ao seu destino. Conte conosco para
          armazenar seus produtos com qualidade e confiança na GH.
        </p>
        <a className={`${isVisible ? "visible" : ""}`}>
          <button>Ver vagas em aberto</button>
        </a>
      </div>
    </VisibilitySensor>
  );
}
export default WarehouseTwo;
