import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from '../../../assets/img/about/aboutOne/img1.webp';

function AboutOne(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility>
        <div className={`aboutOne ${isVisible ? 'visible' : ''}`}>
            <VisibilitySensor partialVisibility={false}>
            <div className="aboutOneC1">
                <img className={`${isVisible ? 'visible' : ''}`} src={img1} alt="" width={'500px'}/>
                <div className="text">
                    <h1 className={`${isVisible ? 'visible' : ''}`}><span>13 anos</span> de história</h1>
                    <p className={`${isVisible ? 'visible' : ''}`}>
                        Nascemos no coração logístico de Itajaí, Santa Catarina, e desde 2011, a GH vem redefinindo o 
                        transporte rodoviário de cargas com um olhar no futuro. Com 13 anos de história, nosso crescimento 
                        espelha a evolução do setor, impulsionado por um serviço que combina pontualidade e personalização 
                        com uma visão humana, sustentável e tecnologicamente inovadora.
                    </p>
                </div>
            </div>
            </VisibilitySensor>
        </div>
        </VisibilitySensor>
    )
}

export default AboutOne;