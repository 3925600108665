import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/warehouseStructure/warehouseSeven/img1.webp";

function WarehouseSeven() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className="warehouseSeven">
        <h1 className={`${isVisible ? "visible" : ""}`}>
          Sistema preventivo de incêndio
        </h1>
        <p className={`${isVisible ? "visible" : ""}`}>
          Priorizamos a segurança em nossos armazéns com um sistema preventivo de incêndio robusto, incluindo detecção de fumaça, extintores e 
          hidrantes estrategicamente distribuídos. Possuímos duas centrais de controle operacionais, uma casa de bombas e reservatório de água 
          abastecido por captação de água da chuva. Contamos ainda com uma equipe de brigadistas treinados para garantir a proteção de nossas instalações, 
          mercadorias e colaboradores.
        </p>
        <img
          className={`${isVisible ? "visible" : ""}`}
          src={img1}
          alt=""
          width={"200px"}
        />
      </div>
    </VisibilitySensor>
  );
}
export default WarehouseSeven;
