import React, { useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';

import VisibilitySensor from 'react-visibility-sensor';

import 'swiper/css';
import 'swiper/css/navigation';

import {Autoplay} from "swiper/modules";

import img1 from '../../../assets/img/home/welcome/welcome1.webp';
import img2 from '../../../assets/img/home/welcome/welcome2.webp';
import img3 from '../../../assets/img/home/welcome/welcome3.webp';
import img4 from '../../../assets/img/home/welcome/welcome4.webp';
import img5 from '../../../assets/img/home/welcome/welcome5.webp';
import img6 from '../../../assets/img/home/welcome/welcome6.webp';
import img7 from '../../../assets/img/home/welcome/welcome7.webp';
import img8 from '../../../assets/img/home/welcome/welcome8.webp';
import imgBg from "../../../assets/img/home/welcome/bg.webp";

const breakpoints = {
    1200: {
      slidesPerView: 3,
    },
    700: {
      slidesPerView: 2,
    },
    0:{
      slidesPerView: 1,
    }
  }

function HomeWelcome(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
            <section>
                <div className="homeWelcome">
                    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
                    <div>
                    <div className="homeWelcomeTitle">
                        <h1 className={`${isVisible ? 'visible' : ''}`}>Bem-vindo(a)!</h1>
                        <p className={`${isVisible ? 'visible' : ''}`}>Facilitamos a logística para impulsionar negócios em direção aos seus objetivos, conectando mercados, indústrias e pessoas de maneira significativa.</p>
                    </div>
                    <div className="homeWelcome2">
                    <Swiper className={`${isVisible ? 'visible' : ''}`}
                            breakpoints={breakpoints}
                            keyboard={true}
                            spaceBetween={10}
                            slidesPerView={'3'}
                            modules={[Autoplay]}
                            speed={700}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                        >
                        <SwiperSlide>
                            <div className={`card card1`}>
                                <img src={img1} alt="" width={'400px'}/>
                                <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`card card3`}>
                                <img src={img2} alt="" width={'400px'}/>
                                <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`card card4`}>
                                <img src={img3} alt="" width={'400px'}/>
                                <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`card card5`}>
                                <img src={img4} alt="" width={'400px'}/>
                                <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`card card6`}>
                                <img src={img5} alt="" width={'400px'}/>
                                <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`card card7`}>
                                <img src={img6} alt="" width={'400px'}/>
                                <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`card card8`}>
                                <img src={img7} alt="" width={'400px'}/>
                                <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`card card8`}>
                                <img src={img8} alt="" width={'400px'}/>
                                <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                            </div>
                        </SwiperSlide>
                        </Swiper>
                    </div>
                    </div>
                    </VisibilitySensor>
                </div>
        </section>
    )
}

export default HomeWelcome;