import NavbarWhite from "../../components/NavbarWhite";
import MediaButtons from "../../components/MediaButtons";
import Footer from "../../components/Footer";
import ButtonTop from "../../components/ButtonTop";
import CookiesAccept from "../../components/CookiesAccept";

import ReportsOne from "./components/ReportsOne";

function AnonymousReports() {

  return (
    <div id="anonymousReports">
      <NavbarWhite></NavbarWhite>
      <MediaButtons></MediaButtons>
      <ButtonTop></ButtonTop>
      <CookiesAccept></CookiesAccept>
      <ReportsOne></ReportsOne>
      <Footer></Footer>
    </div>
  );
}

export default AnonymousReports;
