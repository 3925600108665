import NavbarWhite from "../../components/NavbarWhite";
import MediaButtons from "../../components/MediaButtons";
import ButtonTop from "../../components/ButtonTop";
import Footer from "../../components/Footer";
import CookiesAccept from "../../components/CookiesAccept";

import EsgOne from "./components/EsgOne";
import EsgTwo from "./components/EsgTwo";
import EsgThree from "./components/EsgThree";
import EsgFour from "./components/EsgFour";
import EsgFive from "./components/EsgFive";
import EsgSix from "./components/EsgSix";
import EsgSeven from "./components/EsgSeven";
import EsgEight from "./components/EsgEight";
import EsgNine from "./components/EsgNine";
import EsgTen from "./components/EsgTen";
import EsgEleven from "./components/EsgEleven";

function Esg() {
  return (
    <div>
      <NavbarWhite></NavbarWhite>
      <MediaButtons></MediaButtons>
      <ButtonTop></ButtonTop>
      <CookiesAccept></CookiesAccept>
      <EsgEleven></EsgEleven>
      <EsgTen></EsgTen>
      <EsgThree></EsgThree>
      <EsgFive></EsgFive>
      <EsgSix></EsgSix>
      {/* <EsgTwo></EsgTwo> */}
      <EsgFour></EsgFour>
      <EsgEight></EsgEight>
      <EsgNine></EsgNine>
      <EsgOne></EsgOne>
      <EsgSeven></EsgSeven>
      <Footer></Footer>
    </div>
  );
}

export default Esg;
