import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import VisibilitySensor from "react-visibility-sensor";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

import img1 from "../../../assets/img/home/services/img1.webp";
import img2 from "../../../assets/img/home/services/img2.webp";
import button from "../../../assets/img/home/services/homeServicesButton.webp";

import arrowRight from "../../../assets/img/components/arrow-right.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";

const data = [
  {
    id: 1,
    class: "card-bg blue",
    title: "Transporte Rodoviário",
    content:
      "Conheça o que a GH tem de melhor em transporte rodoviário! Como operador logístico líder, estamos em constante crescimento e sempre trazendo novidades para melhorar nossos serviços. Na GH, investimos em tecnologia avançada, treinamos nossa equipe continuamente, cuidamos de nossa frota moderna e otimizamos nossos processos para garantir a máxima eficiência. Junte-se a nós e descubra uma nova era de excelência no transporte rodoviário!",
    imageUrl: img2,
  },
  {
    id: 2,
    class: "card-bg orange",
    title: "Armazenamento",
    content:
      "Na GH, oferecemos precisão de estoque, agilidade na separação e expedição, monitoramento em tempo real e segurança robusta. Além disso, disponibilizamos espaço para expansão, garantindo que suas necessidades de armazenamento sejam sempre atendidas de forma eficiente e escalável.",
    imageUrl: img1,
  },
  {
    id: 3,
    class: "card-bg blue",
    title: "Transporte Rodoviário",
    content:
      "Conheça o que a GH tem de melhor! Estamos crescendo e sempre trazendo novidades. Na GH, trabalhamos duro para melhorar sempre. Investimos em tecnologia, treinamos nossa equipe, cuidamos das nossas frotas e melhoramos nossos processos. Queremos oferecer o melhor serviço possível, sempre buscando qualidade e eficiência. Venha conosco e descubra uma nova era de qualidade e serviço!",
    imageUrl: img2,
  },
  {
    id: 4,
    class: "card-bg orange",
    title: "Armazenamento",
    content:
      "Na GH, oferecemos precisão de estoque, agilidade na separação e expedição, monitoramento em tempo real e segurança robusta. Além disso, disponibilizamos espaço para expansão, garantindo que suas necessidades de armazenamento sejam sempre atendidas de forma eficiente e escalável.",
    imageUrl: img1,
  },
  {
    id: 5,
    class: "card-bg blue",
    title: "Transporte Rodoviário",
    content:
      "Conheça o que a GH tem de melhor! Estamos crescendo e sempre trazendo novidades. Na GH, trabalhamos duro para melhorar sempre. Investimos em tecnologia, treinamos nossa equipe, cuidamos das nossas frotas e melhoramos nossos processos. Queremos oferecer o melhor serviço possível, sempre buscando qualidade e eficiência. Venha conosco e descubra uma nova era de qualidade e serviço!",
    imageUrl: img2,
  },
  {
    id: 6,
    class: "card-bg orange",
    title: "Armazenamento",
    content:
      "Na GH, oferecemos precisão de estoque, agilidade na separação e expedição, monitoramento em tempo real e segurança robusta. Além disso, disponibilizamos espaço para expansão, garantindo que suas necessidades de armazenamento sejam sempre atendidas de forma eficiente e escalável.",
    imageUrl: img1,
  },
];

const breakpoints = {
  1200: {
    slidesPerView: 2,
  },
  900: {
    slidesPerView: 1,
  },
  700: {
    slidesPerView: 2,
  },
  0: {
    slidesPerView: 1,
    effect: "none",
  },
};

function HomeServices() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <section>
        <div className={`homeServices ${isVisible ? "visible" : ""}`}>
          <div className="homeServicesText">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              Nossos <span>serviços</span>
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              Na GH, somos um operador logístico completo, oferecendo uma
              variedade de serviços para atender às suas necessidades de
              transporte e armazenamento. Desde transporte rodoviário até
              soluções de armazenagem e gestão de estoque, estamos aqui para
              tornar sua operação mais eficiente e ágil. Nossa frota moderna e
              tecnologias avançadas garantem a segurança e a pontualidade que
              você precisa. Conte conosco para soluções logísticas completas e
              personalizadas.
            </p>
            <a href="/services" reloadDocument>
              <img
                className={`${isVisible ? "visible" : ""}`}
                src={button}
                alt=""
                width={"200px"}
              />
            </a>
          </div>
          <Swiper
            className={`${isVisible ? "visible" : ""}`}
            spaceBetween={0}
            modules={[Navigation]}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            speed={800}
            keyboard={true}
            loop={true}
            breakpoints={breakpoints}
            slidesPerView={2}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <div className="swiper-button-next">
              <img src={arrowRight} width={"35px"} height={"35px"} alt="" />
            </div>
            {data.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={item.class}>
                  <div className="card">
                    <img
                      src={item.imageUrl}
                      alt=""
                      width={"150px"}
                      height={"150px"}
                    />
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-button-prev">
              <img src={arrowLeft} width={"35px"} height={"35px"} alt="" />
            </div>
          </Swiper>
        </div>
      </section>
    </VisibilitySensor>
  );
}

export default HomeServices;
