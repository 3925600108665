import React, { useState, useEffect } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/esg/esgSix/esgSix.webp";

import card from "../..//../assets/img/esg/esgSix/card.webp";

function EsgSix() {
  const [count, setCount] = useState(0);
  const [startCounting, setStartCounting] = useState(false);

  useEffect(() => {
    if (startCounting) {
      const targetCount = 1000;
      const duration = 1000; // Duração em milissegundos
      const increment = targetCount / duration;

      const interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount + increment >= targetCount) {
            clearInterval(interval);
            return targetCount;
          }
          return prevCount + increment;
        });
      }); // Intervalo de 10 milissegundos (ajuste conforme necessário)

      return () => clearInterval(interval);
    }
  }, [startCounting]);

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setStartCounting(true);
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div id="esgSix" className={`esgSix ${isVisible ? "visible" : ""}`}>
        <img className={`sixImg ${isVisible ? "visible" : ""}`} src={img1} alt="" />
        <div className="esgSixTitle">
          <h1 className={` ${isVisible ? "visible" : ""}`}>
            Projeto Tampinhas do Bem
          </h1>
          <p className={` ${isVisible ? "visible" : ""}`}>
            Em 2023, lançamos uma iniciativa de coleta de tampinhas em todas as
            nossas instalações, e ficamos muito satisfeitos com a alta adesão
            interna. Todas as tampinhas coletadas são destinadas a instituições
            que as trocam por cadeiras de rodas, além de contribuírem para
            arrecadar fundos para a castração de animais de rua.
          </p>
          <div className="tampinhas">
            <img
              className={` ${isVisible ? "visible" : ""}`}
              src={card}
              alt=""
              width={"400"}
            />
            <p className={` ${isVisible ? "visible" : ""}`}>
              <span>+ {count.toLocaleString()}</span>
              <br />
              tampinhas coletadas
            </p>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default EsgSix;
