import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/home/career/img2.webp";

function HomeCareer() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
        <div className={`homeCareer ${isVisible ? "visible" : ""}`}>
          <div className="careerTitle">
            <h1 className={`${isVisible ? "visible" : ""}`}>#CarreiraGH</h1>
          </div>
          <div className="careerContent">
            <div className="text">
              <p className={`${isVisible ? "visible" : ""}`}>
                Na GH, estamos comprometidos em impulsionar o crescimento
                pessoal e profissional de nossa equipe. Se você busca uma
                carreira segura e repleta de oportunidades de desenvolvimento,
                você está no lugar certo!
              </p>
              <a
                className="button"
                href={"https://ghtransporte.vagas.solides.com.br/"}
                target="_blank"
                rel="noreferrer"
                reloadDocument
              >
                <button>Candidatar-se</button>
              </a>
            </div>
            <div className="arrows2">
              <img
                className={`img3 ${isVisible ? "visible" : ""}`}
                src={img1}
                alt=""
                width={"200px"}
              />
              <img
                className={`img4 ${isVisible ? "visible" : ""}`}
                src={img1}
                alt=""
                width={"200px"}
              />
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeCareer;
