import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

function AboutFive(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="aboutFive">
            <div className="aboutFiveTitle">
                <h1 className={`${isVisible ? 'visible' : ''}`}>Política Geral</h1>
            </div>
            <div className="aboutFiveText">
                <p className={`text1 ${isVisible ? 'visible' : ''}`}>
                    A GH, posiciona-se com foco em promover soluções logísticas completas e de alta qualidade, com integridade organizacional 
                    e valor aos seus clientes, para isto compromete-se juntamente com suas partes interessadas a:
                    <br />
                    Trabalhar com foco no desenvolvimento sustentável, valorização das pessoas, produtividade nos processos, 
                    transparência, zelo pela saúde e segurança nas operações, preservação ao meio ambiente, garantia pela excelência operacional 
                    com elevado nível de serviços e segurança para todos os seus clientes, a fim de alcançar os resultados previstos no sistema de 
                    gestão integrado, de acordo com o planejamento estratégico da organização.
                </p>
                <p className={`text2 ${isVisible ? 'visible' : ''}`}>
                    Prevenir incidentes, acidentes, furtos, roubos, abusos, poluição do meio ambiente e doenças ocupacionais, atuando na preservação 
                    da saúde, segurança, meio ambiente e qualidade em suas operações e conscientização das pessoas envolvidas, através de procedimentos 
                    de trabalho adequados, envolvimento dos trabalhadores, qualificação e conscientização constantes, proibindo o uso de álcool e drogas 
                    durante o trabalho, além de manter canais de comunicação adequados com as partes interessadas.
                    <br />
                    Cumprir os requisitos legais aplicáveis, cultivando a ética e a diversidade, incentivando a inovação e a responsabilidade socioambiental, 
                    com foco na melhoria contínua e eficácia do sistema de gestão integrado.
                </p>
            </div>
            <div className="version">
                <p>Revisão: 03 - 17/05/2024</p>
            </div>
        </div>
        </VisibilitySensor>
    )
}

export default AboutFive;