import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import card1 from "../../../assets/img/home/certifications/card1.webp";
import card2 from "../../../assets/img/home/certifications/card2.webp";
import card3 from "../../../assets/img/home/certifications/card3.webp";
import card4 from "../../../assets/img/home/certifications/card4.webp";
import card5 from "../../../assets/img/home/certifications/card5.webp";
import card6 from "../../../assets/img/home/certifications/card6.webp";
import card7 from "../../../assets/img/home/certifications/card7.webp";
import card8 from "../../../assets/img/home/certifications/card8.webp";
import card9 from "../../../assets/img/home/certifications/card9.webp";
import card10 from "../../../assets/img/home/certifications/card10.webp";
import card11 from "../../../assets/img/home/certifications/card11.webp";
import card12 from "../../../assets/img/home/certifications/card12.webp";
import card13 from "../../../assets/img/home/certifications/card13.webp";
import card14 from "../../../assets/img/home/certifications/card14.webp";
import card15 from "../../../assets/img/home/certifications/card15.webp";
import card16 from "../../../assets/img/home/certifications/card16.webp";
import card17 from "../../../assets/img/home/certifications/card17.webp";
import card18 from "../../../assets/img/home/certifications/card18.webp";
import card19 from "../../../assets/img/home/certifications/card19.webp";
import card20 from "../../../assets/img/home/certifications/card20.webp";

function HomeCertifications() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="homeCertifications">
          <div className="homeCertificationsText">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              Licenças e{" "}
              <span className={`${isVisible ? "visible" : ""}`}>
                certificados
              </span>
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              Com anos de expertise no mercado, acumulamos certificações que
              ressaltam nossa qualidade, segurança e responsabilidade
              socioambiental. Assim como confere nossa legalidade de operação.
            </p>
          </div>
          <div className="homeCertificationsCards">
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={card1} alt="" width={"100px"} height={"111px"} />
              <div className="cardText">
                <p>
                  O SASSMAQ é uma certificação, sendo destinada aos transportadores de produtos químicos, onde somos certificados desde 2016.
                </p>
              </div>
            </div>
            <div className={`card2 card ${isVisible ? "visible" : ""}`}>
              <img src={card2} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  O Programa Atuação Responsável, da Abiquim, é um compromisso da indústria química 
                  com a excelência em saúde, segurança e meio ambiente, visando melhorar constantemente 
                  seus padrões nessas áreas, onde somos certificados.
                </p>
              </div>
            </div>
            <div className={`card3 card ${isVisible ? "visible" : ""}`}>
              <img src={card3} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  A NBR ISO 9001 é uma norma aplicada para desenvolver um sistema 
                  de gestão de qualidade, na qual somos certificados desde 2016.
                </p>
              </div>
            </div>
            <div className={`card4 card ${isVisible ? "visible" : ""}`}>
              <img src={card4} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  A NBR ISO 14001 é uma norma aplicada para desenvolver um 
                  sistema de gestão ambiental, na qual somos certificados desde 2018.
                </p>
              </div>
            </div>
            <div className={`card5 card ${isVisible ? "visible" : ""}`}>
              <img src={card5} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  A ISO 45001 é uma norma aplicada para desenvolver um sistema de gestão 
                  de saúde e segurança, na qual somos certificados desde 2018.
                </p>
              </div>
            </div>
            <div className={`card6 card ${isVisible ? "visible" : ""}`}>
              <img src={card6} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Possuímos a licença ambiental de operação de terminal rodoviário de cargas, a qual habilita 
                  nossa operação a executar atividades de armazenagem em geral, assim como a armazenar produtos perigosos.
                </p>
              </div>
            </div>
            <div className={`card7 card ${isVisible ? "visible" : ""}`}>
              <img src={card7} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Todos os nossos veículos possuem a LETPP para efetuar
                  transporte de produtos perigosos que passem dentro da cidade
                  de São Paulo.
                </p>
              </div>
            </div>
            <div className={`card8 card ${isVisible ? "visible" : ""}`}>
              <img src={card8} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Todos os nossos veículos possuem a LAC para efetuar transporte 
                  de produtos perigosos dentro do estado de Santa Catarina.
                </p>
              </div>
            </div>
            <div className={`card9 card ${isVisible ? "visible" : ""}`}>
              <img src={card9} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Nossas operações têm o CR, assim como a AATIPP, sendo regulamentadas 
                  conforme o órgão e renovadas trimestralmente.
                </p>
              </div>
            </div>
            <div className={`card10 card ${isVisible ? "visible" : ""}`}>
              <img src={card10} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Desde 2022, somos reconhecidos como uma das melhores empresas 
                  para se trabalhar no Brasil e no estado de Santa Catarina.
                </p>
              </div>
            </div>
            <div className={`card11 card ${isVisible ? "visible" : ""}`}>
              <img src={card11} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Somos autorizados pela Anvisa para armazenar, expedir e transportar correlatos, medicamentos, cosméticos e saneantes.
                </p>
              </div>
            </div>
            <div className={`card12 card ${isVisible ? "visible" : ""}`}>
              <img src={card12} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Somos habilitados pela Polícia Federal para transportar e armazenar produtos químicos controlados.
                </p>
              </div>
            </div>
            <div className={`card13 card ${isVisible ? "visible" : ""}`}>
              <img src={card13} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Somos habilitados pelo Exército para transportar e armazenar produtos químicos controlados.
                </p>
              </div>
            </div>
            <div className={`card14 card ${isVisible ? "visible" : ""}`}>
              <img src={card14} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Somos autorizados pelo Ministério da Agricultura, Pecuária e Abastecimento (MAPA) para o armazenamento de fertilizantes em nossas instalações.
                </p>
              </div>
            </div>
            <div className={`card15 card ${isVisible ? "visible" : ""}`}>
              <img src={card15} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  O Programa Olho Vivo na Estrada busca evitar acidentes com produtos químicos no transporte 
                  rodoviário, promovendo a segurança e prevenindo comportamentos arriscados. Nós contribuímos 
                  para essa causa conscientizando os motoristas sobre práticas seguras.
                </p>
              </div>
            </div>
            <div className={`card16 card ${isVisible ? "visible" : ""}`}>
              <img src={card16} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  A GH se engaja no Programa de Logística Verde Brasil (PLVB), comprometida com a redução do 
                  impacto ambiental em suas operações logísticas. O PLVB busca diminuir emissões de gases estufa 
                  e aprimorar a eficiência logística nacional, incentivando práticas sustentáveis em toda a cadeia de suprimentos.
                </p>
              </div>
            </div>
            <div className={`card17 card ${isVisible ? "visible" : ""}`}>
              <img src={card17} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Em 2023, a GH reforçou seu compromisso socioambiental ao conquistar o Selo Ouro do Programa 
                  de Logística Verde Brasil. Apresentou relatórios abrangentes de 2021 a 2023, validados pela 
                  equipe do PLVB. O Selo Ouro reconhece empresas comprometidas com práticas sustentáveis, evidenciando 
                  o compromisso contínuo da GH com a excelência ambiental.
                </p>
              </div>
            </div>
            <div className={`card18 card ${isVisible ? "visible" : ""}`}>
              <img src={card18} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Somos uma empresa reconhecida com quatro estrelas no programa Pellets Zero, 
                  dedicada a realizar ações para reduzir o impacto ambiental das resinas em córregos,
                  rios e mares, causado pela perda no processo logístico.
                </p>
              </div>
            </div>
            <div className={`card19 card ${isVisible ? "visible" : ""}`}>
              <img src={card19} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  O Programa Na Mão Certa sensibiliza e mobiliza pessoas, empresas, governos e 
                  organizações da sociedade civil, com o objetivo de formar um Círculo de Proteção 
                  para a prevenção e o enfrentamento da exploração sexual contra crianças e adolescentes 
                  (ESCA), em todo o País.
                </p>
              </div>
            </div>
            <div className={`card20 card ${isVisible ? "visible" : ""}`}>
              <img src={card20} alt="" width={"100px"} height={"111px"}/>
              <div className="cardText">
                <p>
                  Somos uma Empresa Laço Amarelo, certificada pelo Observatório Nacional de Segurança Viária 
                  (ONSV), com a missão de fomentar a conscientização no trânsito.
                </p>
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeCertifications;
