import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

function HomeFleet() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="homeFleet">
          <div className="fleet">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              O destaque de uma frota de padrão elevado
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              Os veículos da GH são modernos e bem cuidados, o que garante
              segurança e eficiência em todas as viagens. Nossa prioridade pela
              qualidade é visível na manutenção frequente e na renovação
              constante da nossa frota, o que nos permite oferecer um transporte
              confiável e de alta qualidade para nossos clientes.
            </p>
            <a
              className={`${isVisible ? "visible" : ""}`}
              href="/fleetStructure"
              reloadDocument
            >
              <button>Ver mais...</button>
            </a>
          </div>
          <div className="warehouse">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              Armazenagem estratégica para o crescimento do seu negócio
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              Otimize sua logística com a GH. Nossas soluções de armazenagem vão
              além do convencional, impulsionando o crescimento e a eficiência
              de seu negócio. Adaptamos nossas instalações às suas necessidades,
              garantindo segurança e agilidade em suas entregas."
            </p>
            <a
              className={`${isVisible ? "visible" : ""}`}
              href="/warehouseStructure"
              reloadDocument
            >
              <button>Ver mais...</button>
            </a>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeFleet;
