import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import img1 from "../../../assets/img/career/careerOne/selo-large2.webp";
import bg from "../../../assets/img/career/careerOne/careerOne-bg.webp"

function CareerOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className={`careerOne ${isVisible ? "visible" : ""}`}>
        <div className="img1">
          <img 
            className={` ${isVisible ? "visible" : ""}`}
            src={bg} alt="" 
          />
        </div>
        <div className="img2">
          <img
            className={` ${isVisible ? "visible" : ""}`}
            src={img1}
            width={"200"}
            alt=""
          />
        </div>
        <div className="careerText">
          <h1 className={` ${isVisible ? "visible" : ""}`}>
          Junte-se à GH: <br /> <span>Onde Carreiras Crescem</span>{" "}
          </h1>
          <p className={` ${isVisible ? "visible" : ""}`}>
            Na GH, valorizamos relacionamentos autênticos baseados na confiança, transparência e respeito. 
            Nossa dedicação vai além dos negócios, refletindo-se em ações de responsabilidade social que beneficiam 
            comunidades, contribuindo para um futuro mais justo e inclusivo. Investimos no bem-estar e no desenvolvimento 
            de nossos colaboradores, promovendo um ambiente de trabalho saudável e positivo, onde a saúde mental e o equilíbrio 
            entre trabalho e vida pessoal são priorizados.
          </p>
          <p className={` ${isVisible ? "visible" : ""}`}>
            Estamos comprometidos com o crescimento profissional da nossa equipe, oferecendo programas de desenvolvimento 
            e oportunidades de avanço. Cada desafio é uma chance de aprendizado, e cada conquista é um passo rumo ao sucesso coletivo. 
          </p>
          <p className={` ${isVisible ? "visible" : ""}`}>            
            Explore as vagas em aberto e leia os depoimentos de nossos colaboradores, que compartilham suas experiências e 
            trajetórias na GH. Faça parte de uma equipe dedicada à excelência, inovação e impacto positivo na sociedade. 
            Junte-se a nós e construa uma carreira significativa.
          </p>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default CareerOne;
