import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import button from "../../../assets/img/services/servicesFive/button.webp";

function ServicesFive() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className={`servicesFive-bg ${isVisible ? "visible" : ""}`}>
        <div className="servicesFive">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Entre em contato para solicitar um orçamento
          </h1>
          <div className={`line ${isVisible ? "visible" : ""}`}></div>
          <a
            className={`${isVisible ? "visible" : ""}`}
            href="/contact"
            rel="noreferrer"
            reloadDocument
          >
            <img src={button} alt="" />
            <p>Solicitar orçamento</p>
          </a>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default ServicesFive;
