import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/warehouseStructure/warehouseEight/img1.webp";

function WarehouseEight(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
            <div className="warehouseEight"> 
              <img className={`${isVisible ? 'visible' : ''}`} src={img1} alt="" width={'250px'} />
              <h1 className={`${isVisible ? 'visible' : ''}`}>Seguro que protege sua carga</h1>
              <p className={`${isVisible ? 'visible' : ''}`}>
                Na GH, nossos armazéns são mais do que apenas espaços de armazenamento. 
                Eles são protegidos por seguros abrangentes, garantindo tranquilidade e segurança para sua carga. 
                Conte conosco para armazenar seus produtos com confiança, sabendo que estão protegidos contra qualquer imprevisto.
              </p>
            </div>
        </VisibilitySensor>
    )
}
export default WarehouseEight;