import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/esg/esgTen/img1.webp";
import img2 from "../../../assets/img/esg/esgTen/img2.webp";
import arrow from "../../../assets/img/esg/esgTen/arrow2.webp";

import iso1 from "../../../assets/img/home/certifications/card1.webp";
import iso2 from "../../../assets/img/home/certifications/card3.webp";
import iso3 from "../../../assets/img/home/certifications/card4.webp";
import iso4 from "../../../assets/img/home/certifications/card5.webp";

function EsgTen() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div id="esgTen" className={`esgTen ${isVisible ? "visible" : ""}`}>
        <div className="esgTenLogo">
          <img
            src={img1}
            alt=""
            className={`img gheco ${isVisible ? "visible" : ""}`}
          />
          <img
            src={img2}
            alt=""
            className={`img gheco ${isVisible ? "visible" : ""}`}
          />
          <div className="links">
            <a
              href="#esgThree"
              reloadDocument
              className={`link5 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" width={"40px"} />
              <p>GHEco Driving</p>
            </a>
            <a
              href="#esgFive"
              reloadDocument
              className={`link2 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" width={"40px"} />
              <p>Programa Pellets Zero</p>
            </a>
            <a
              href="#esgSix"
              reloadDocument
              className={`link5 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" width={"40px"} />
              <p>Projeto Tampinhas do Bem</p>
            </a>
            <a
              href="#esgFour"
              reloadDocument
              className={`link3 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" width={"40px"} />
              <p>Programa PLVB</p>
            </a>
            <a
              href="#esgFour"
              reloadDocument
              className={`link4 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" width={"40px"} />
              <p>Selo Ouro</p>
            </a>
          </div>
        </div>
        <div className="esgTenText">
          {/* <div className="gheco">
              <h1 className={`${isVisible ? 'visible' : ''}`}>GH<span>Eco</span></h1>
            </div> */}
          <div className="politica">
            <h2 className={`${isVisible ? "visible" : ""}`}>
              Política do programa
            </h2>
            <p className={`${isVisible ? "visible" : ""}`}>
              O programa GHEco da GH é uma iniciativa essencialmente voltada
              para a sustentabilidade e a conservação de recursos naturais.
              Reconhecendo a grande importância de preservar o meio ambiente, a
              empresa dedica-se incessantemente a monitorar e implementar as
              melhores práticas em todas as suas operações. O objetivo
              primordial do programa GHEco é minimizar o impacto ambiental e
              otimizar o uso de recursos naturais, como água, energia e
              combustível. Para alcançar esse fim, a GH implementa sistemas de
              monitoramento para avaliar o consumo e identificar oportunidades
              de redução e aumento de eficiência. Através do GHEco, a empresa
              promove o uso responsável dos recursos naturais, adotando medidas
              como a captação e reutilização de água da chuva, a incorporação de
              tecnologias e equipamentos mais eficientes em termos de energia, e
              a busca por rotas e planejamentos logísticos que reduzam o consumo
              de combustível.
            </p>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default EsgTen;
