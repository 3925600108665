import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img3 from "../../../assets/img/warehouseStructure/warehouseNine/img3.webp";

function WarehouseNine(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
            <div className={`warehouseNine ${isVisible ? 'visible' : ''}`}>
                <div className="nineText">
                    <h1 className={`${isVisible ? 'visible' : ''}`}><span>Unidade da GH em Itapoá - SC</span><br />A 15 km do porto de Itapoá.</h1>                    
                    <p className={`${isVisible ? 'visible' : ''}`}>
                        A unidade da GH em Garuva/Itapoá-SC, reflete nosso compromisso com o conhecimento em transporte e logística. 
                        Nosso armazém, com aproximadamente 6.000 m2, equipado com 04 docas e 01 porta de acesso exclusivo, oferece maior 
                        eficiência para suas operações. Localizado no Braspark Condomínio Logístico, à beira da Rodovia SC 417, no 11.915, 
                        Bairro Mina Velha, Garuva-SC (Itapoá), nossa filial proporciona uma localização estratégica para atender às necessidades 
                        logísticas da região. Estamos comprometidos com a melhoria contínua e convidamos você a fazer parte dessa jornada conosco. 
                        Se precisar de cotação para nossos serviços, entre em contato. Estamos prontos para atendê-lo com tudo o que você necessita 
                        em transporte e logística.
                    </p>
                </div>
                <div className="nineImg">
                    <img className={`img1 ${isVisible ? 'visible' : ''}`} src={img3} alt="" width={'600px'}/>
                </div>
            </div>
        </VisibilitySensor>
    )
}
export default WarehouseNine;