import NavbarWhite from "../../components/NavbarWhite";
import MediaButtons from "../../components/MediaButtons";
import ButtonTop from "../../components/ButtonTop";
import Footer from "../../components/Footer";
import CookiesAccept from "../../components/CookiesAccept";

import FleetOne from "./components/FleetOne";
import FleetTwo from "./components/FleetTwo";
import FleetThree from "./components/FleetThree";
import FleetFour from "./components/FleetFour";
/* import FleetFive from "./components/FleetFive"; */
import FleetSix from "./components/FleetSix";

function FleetStructure() {
  return (
    <>
      <NavbarWhite />
      <MediaButtons />
      <ButtonTop></ButtonTop>
      <CookiesAccept></CookiesAccept>
      <FleetOne></FleetOne>
      <FleetTwo></FleetTwo>
      <FleetThree></FleetThree>
      <FleetFour></FleetFour>
      {/* <FleetFive></FleetFive> */}
      <FleetSix></FleetSix>
      <Footer />
    </>
  );
}

export default FleetStructure;
