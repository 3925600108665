import React from "react";

import Navbar from "../../components/NavbarWhite";
import MediaButtons from "../../components/MediaButtons";
import Footer from "../../components/Footer";
import ButtonTop from "../../components/ButtonTop";
import CookiesAccept from "../../components/CookiesAccept";

import AboutOne from "./components/AboutOne";
import AboutOneB from "./components/AboutOneB";
import AboutTwo from "./components/AboutTwo";
import AboutThree from "./components/AboutThree";
import AboutFour from "./components/AboutFour";
import AboutFive from "./components/AboutFive";
import AboutSeven from "./components/AboutSeven";
import AboutEight from "./components/AboutEight";
import AboutNine from "./components/AboutNine";

function About() {
  return (
    <div id="about">
      <Navbar></Navbar>
      <MediaButtons></MediaButtons>
      <ButtonTop></ButtonTop>
      <CookiesAccept></CookiesAccept>
      <AboutOne></AboutOne>
      <AboutOneB></AboutOneB>
      <AboutTwo></AboutTwo>
      <AboutThree></AboutThree>
      <AboutFour></AboutFour>
      <AboutFive></AboutFive>
      <AboutEight></AboutEight>
      <AboutNine></AboutNine>
      <AboutSeven></AboutSeven>
      <Footer></Footer>
    </div>
  );
}

export default About;
