import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/warehouseStructure/warehouseSeven/img2.webp";

function WarehouseEleven() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className="warehouseEleven">
        <img
          className={`${isVisible ? "visible" : ""}`}
          src={img1}
          alt=""
          width={"300px"}
        />
        <div className="elevenText">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Empilhadeiras Elétricas: A Inovação Sustentável para Armazéns
            Modernos
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            As empilhadeiras elétricas são uma inovação revolucionária para
            armazéns modernos. Além de contribuírem para a redução da pegada de
            carbono, essas máquinas oferecem uma série de benefícios e
            tecnologias avançadas. Com operação silenciosa e livre de emissões,
            elas proporcionam um ambiente de trabalho mais seguro e saudável.
            Sua capacidade de manobra precisa e eficiente permite otimizar o
            espaço de armazenamento, aumentando a produtividade e reduzindo os
            custos operacionais. Com a empilhadeira elétrica, seu armazém estará
            preparado para enfrentar os desafios logísticos do futuro com
            eficiência e sustentabilidade.
          </p>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default WarehouseEleven;
