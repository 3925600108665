import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

function AboutNine(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className={`aboutNine ${isVisible ? 'visible' : ''}`}>
            <VisibilitySensor onChange={onChange} partialVisibility={false}>
            <iframe 
                className={`${isVisible ? 'visible' : ''}`}
                width="900" 
                height="380" 
                src="https://www.youtube-nocookie.com/embed/trPYz1uoXks?si=1Igtkun7Uhoi1o2b"
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen>    
            </iframe>
            </VisibilitySensor>
        </div>
        </VisibilitySensor>
    )
}

export default AboutNine;