import React, { useState, useEffect } from 'react';
import img1 from "../assets/img/components/buttonTop.webp";



function ButtonTop(){
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const isScrolled = window.scrollY > 500;
        setScrolled(isScrolled);
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    return(
        <div className={`buttonUp ${scrolled ? "scrolled" : ""}`}>
                <button onClick={scrollToTop}>
                    <div className="buttonTop">
                        <img className="img1" src={img1} alt="" />
                        <img className="img2" src={img1} alt="" />
                        <img className="img3" src={img1} alt="" />
                    </div>
                </button>
                <div className={`buttonText ${scrolled ? "scrolled" : ""}`}>
                  <p>Voltar ao topo</p>
                </div>
        </div>
    )
}
export default ButtonTop;