import { generateClientErrorMessage } from "../utils/generateClientErrorMessage"
import { api } from "../config/api"

export const sendAnonymousReport = async (body) => {
    try {
        const res = await api.post('/sendAnonymousReport', body)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, message: res.data.message }

    } catch (error) {
        console.error(error)
        return { success: false, message: generateClientErrorMessage(error) }
    }
}