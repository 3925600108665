import React, { useState } from "react";

import VisibilitySensor from 'react-visibility-sensor';

import ods1 from '../../../assets/img/home/ods/ods1.webp';
import ods2 from '../../../assets/img/home/ods/ods2.webp';
import ods3 from '../../../assets/img/home/ods/ods3.webp';
import ods4 from '../../../assets/img/home/ods/ods4.webp';
import ods5 from '../../../assets/img/home/ods/ods5.webp';
import ods6 from '../../../assets/img/home/ods/ods6.webp';
import ods7 from '../../../assets/img/home/ods/ods7.webp';
import ods8 from '../../../assets/img/home/ods/ods8.webp';
import ods9 from '../../../assets/img/home/ods/ods9.webp';
import ods10 from '../../../assets/img/home/ods/ods10.webp';
import ods11 from '../../../assets/img/home/ods/ods11.webp';
import ods12 from '../../../assets/img/home/ods/ods12.webp';
import ods13 from '../../../assets/img/home/ods/ods13.webp';
import ods14 from '../../../assets/img/home/ods/ods14.webp';
import ods15 from '../../../assets/img/home/ods/ods15.webp';
import ods16 from '../../../assets/img/home/ods/ods16.webp';
import ods17 from '../../../assets/img/home/ods/ods17.webp';
import lock from '../../../assets/img/home/ods/cadeado.webp';

function HomeOds(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <section>
            <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
            <div className="homeOds">
                <h1 className={`${isVisible ? 'visible' : ''}`}><span className={`${isVisible ? 'visible' : ''}`}>Somos um operador logístico </span>alinhado com o futuro, demonstrando compromisso com os Objetivos de Desenvolvimento Sustentável.</h1>
                <div className="homeOdsCards">
                    <div className={`card1 card disable ${isVisible ? 'visible' : ''}`}>
                        <img src={ods1} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card2 card disable ${isVisible ? 'visible' : ''}`}>
                        <img src={ods2} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card3 card ${isVisible ? 'visible' : ''}`}>
                        <img src={ods3} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card4 card disable ${isVisible ? 'visible' : ''}`}>
                        <img src={ods4} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>    
                    </div>
                    <div className={`card5 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods5} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card6 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods6} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card7 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods7} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card8 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods8} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card9 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods9} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card10 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods10} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card11 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods11} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card12 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods12} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card13 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods13} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card14 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods14} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>    
                    </div>
                    <div className={`card15 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods15} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                    <div className={`card16 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods16} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>   
                    </div>
                    <div className={`card17 card  ${isVisible ? 'visible' : ''}`}>
                        <img src={ods17} alt="" width={'100px'} height={'90px'}/>
                        <div className="lock">
                            <img className="lockImg" src={lock} alt="" width={'30px'} height={"30px"}/>
                        </div>
                    </div>
                </div>
            </div>
            </VisibilitySensor>
        </section>
    )
}

export default HomeOds;