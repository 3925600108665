import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import cardImg from "../../../assets/img/services/servicesFour/card-img.webp";

function ServicesFour() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div className="servicesFour">
        <div className="servicesFourText">
          <h1 className={`${isVisible ? "visible" : ""}`}>Armazenagem</h1>
          <h2 className={`${isVisible ? "visible" : ""}`}>
            Amplie os horizontes do seu negócio com o espaço ideal para seu
            crescimento.
          </h2>
          <p className={`${isVisible ? "visible" : ""}`}>
            A GH se destaca como operador logístico ao oferecer serviços de
            armazenagem essenciais para a eficiência dos processos logísticos.
            Nossa armazenagem otimiza a organização, proporcionando maior
            agilidade e estratégias eficazes para entrega. Não somos apenas um
            armazém convencional; nossa infraestrutura permite armazenar uma
            variedade de materiais e produtos, atendendo a necessidades
            específicas e urgências com excelência.
          </p>
        </div>
        <div className="servicesFourCards">
          <div className="cards1">
            <div className={`card card1 ${isVisible ? "visible" : ""}`}>
              <h3>Operação in House</h3>
              <p>
                A terceirização in house envolve um operador logístico
                gerenciando as operações logísticas dentro da unidade de
                armazenamento, assegurando maior eficiência e controle.
              </p>
            </div>
          </div>
          <div className="cards2">
            <div className={`card card3 ${isVisible ? "visible" : ""}`}>
              <h3>Operação com produtos controlados</h3>
              <p>
                Temos uma vasta expertise com operações com produtos controlados
                pela Anvisa, Polícia Federal, Exército e MAPA, onde oferecemos
                locais adequados para cada tipo de operação.
              </p>
            </div>
            <img
              className={`cardImg ${isVisible ? "visible" : ""}`}
              src={cardImg}
              alt=""
            />
            <div className={`card card4 ${isVisible ? "visible" : ""}`}>
              <h3>Distribuição Eficiente</h3>
              <p>
                Nossa expertise logística garante um percurso sem problemas,
                desde a recepção até a expedição, focando na excelência do
                courier business.
              </p>
            </div>
          </div>
          <div className="cards3">
            <div className={`card card5 ${isVisible ? "visible" : ""}`}>
              <h3>Cross Docking</h3>
              <p>
                Agilidade e economia são os principais objetivos desse serviço
                logístico, otimizando o fluxo de mercadorias para operações mais
                rápidas.
              </p>
            </div>
            <div className={`card card6 ${isVisible ? "visible" : ""}`}>
              <h3>Picking</h3>
              <p>
                Precisão na seleção e preparo de pedidos é crucial para a
                eficiência operacional da sua empresa.
              </p>
            </div>
          </div>
        </div>
        <a
          className={`${isVisible ? "visible" : ""}`}
          href="/warehouseStructure"
          reloadDocument
        >
          <button>Ver mais...</button>
        </a>
      </div>
    </VisibilitySensor>
  );
}

export default ServicesFour;
