import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

function FleetThree() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div className={`fleetThree ${isVisible ? "visible" : ""}`}>
        <h1 className={`${isVisible ? "visible" : ""}`}>
        Uma frota <span>nova</span>  e com <span>alta</span> tecnologia.
        </h1>
        <p className={`${isVisible ? "visible" : ""}`}>
          Na GH, investimos constantemente em tecnologia e inovação para oferecer o melhor serviço de transporte para os nossos clientes. 
          Nossos caminhões são todos EURO 5 / EURO 6 e GNV, garantindo não apenas eficiência operacional, mas também um impacto ambiental reduzido. 
          Com essa tecnologia de ponta, não apenas entregamos suas mercadorias com segurança e pontualidade, mas também contribuímos para um futuro
          mais sustentável. Confie na nossa frota para levar sua carga com a mais alta qualidade e responsabilidade ambiental.
        </p>
      </div>
    </VisibilitySensor>
  );
}
export default FleetThree;
