import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img2 from '../../../assets/img/about/aboutOne/img2.webp';

function AboutOneB(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={460}>
        <div className={`aboutOneB ${isVisible ? 'visible' : ''}`}>
            <div className="aboutOneC2">
                <div className="text">
                    <h1 className={`${isVisible ? 'visible' : ''}`}>História que <span>nos define</span></h1>
                    <p className={`${isVisible ? 'visible' : ''}`}>
                        Nossa jornada é marcada pela evolução constante. Nascemos como uma empresa de transporte e, ao 
                        longo do tempo, nos adaptamos e crescemos junto com as necessidades dos nossos clientes. Nosso 
                        posicionamento dinâmico reflete nossa proatividade e compromisso em ser mais do que simples 
                        prestadores de serviço. Optamos por nos tornar "Solucionadores Logísticos", não apenas movendo 
                        cargas, mas também moldando o mercado com práticas que respeitam o meio ambiente e as comunidades 
                        que servimos. Unimos talentos humanos à tecnologia de ponta para oferecer soluções eficientes e 
                        conectadas para os desafios logísticos de nossos clientes, marcando nosso compromisso não só com o 
                        presente, mas também com o futuro logístico do Brasil.
                    </p>
                </div>
                <img className={`${isVisible ? 'visible' : ''}`} src={img2} alt="" width={'500px'}/>
            </div>
        </div>
        </VisibilitySensor>
    )
}

export default AboutOneB;