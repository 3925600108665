import NavbarWhite from "../../components/NavbarWhite";
import ButtonTop from "../../components/ButtonTop";
import SubNavBlog from "./components/SubNavBlog";
import Footer from "../../components/Footer";
import MediaButtons from "../../components/MediaButtons";
import { BlogProvider } from "./contexts/BlogContext";
import { Outlet } from "react-router-dom";
import CookiesAccept from "../../components/CookiesAccept";

function Blog() {
  return (
    <BlogProvider>
      <NavbarWhite />
      <ButtonTop/>
      <SubNavBlog />
      <CookiesAccept></CookiesAccept>
      <MediaButtons />
      <Outlet />
      <Footer />
    </BlogProvider>
  );
}

export default Blog;
