import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/esg/esgThree/esgThree.webp";

function EsgThree(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
            <div id="esgThree" className={`esgThree ${isVisible ? 'visible' : ''}`}>
                <div className="esgText">
                    <h1 className={`${isVisible ? 'visible' : ''}`}>O futuro começa com uma logística <span>mais sustentável (GNV e Biometano)</span></h1>
                    <p className={`${isVisible ? 'visible' : ''}`}>
                        A sustentabilidade é um pilar essencial da nossa identidade, profundamente enraizado em nossa cultura 
                        para promover um futuro melhor. Guiados por uma política central focada em sustentabilidade, investimos 
                        em tecnologias de motorização que utilizam GNV e biometano como combustíveis. Essas tecnologias reduzem 
                        significativamente as emissões de gases de efeito estufa, contribuindo para as metas de redução de gases 
                        na atmosfera e apoiando a Agenda 2030 da ONU. Nosso compromisso com práticas sustentáveis demonstra nossa 
                        dedicação em construir um futuro mais verde e sustentável para as próximas gerações.
                    </p>
                </div>
                <div className={`esgImg ${isVisible ? 'visible' : ''}`}>
                    <img width={'800px'} src={img1} alt="" />
                </div>
            </div>
        </VisibilitySensor>
    )
}

export default EsgThree;