import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

function FleetSix(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
        <div className="fleetSix">
          <div className={`card1 card ${isVisible ? 'visible' : ''}`}>
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/mKU66ntC5mc?si=y2z7ooVzJmKFn_SP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <div className="cardText">
              <h3>Caminhão Silo</h3>
              <p>
                Agora, o transporte de polietileno e outras cargas secas da 
                indústria química também poderão ter o melhor caminho pelo Brasil.
              </p>
            </div>
          </div>
          <div className={`card2 card ${isVisible ? 'visible' : ''}`}>
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/avfPeE-rLv8?si=AZASih1tVrQTk0mo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <div className="cardText">
              <h3>Série Especial Scania Heróis da Estrada</h3>
              <p>
                Agora, o transporte de polietileno e outras cargas secas da 
                indústria química também poderão ter o melhor caminho pelo Brasil.
              </p>
            </div>
          </div>
          <div className={`card3 card ${isVisible ? 'visible' : ''}`}>
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Wo0IZuEBeCM?si=fm-2ADgQ0D-pKhEh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <div className="cardText">
              <h3>Teste Caminhão Scania GNV</h3>
              <p>
                Somos a primeira transportadora de todos os estados do Sul do Brasil a fazer 
                testes em um caminhão Scania GNV. Obrigada a Scania por ter confiado essa 
                missão inovadora aos nossos motoristas, pois, seguimos juntos buscando o melhor 
                caminho para o meio ambiente e todo país.
              </p>
            </div>
          </div>
          <div className={`card4 card ${isVisible ? 'visible' : ''}`}>
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/t0vVd5hefSw?si=Ull4Yeg0s298b3E1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <div className="cardText">
              <h3>Campanhas Especiais</h3>
              <p>
                Com nossos novos caminhões, queremos espalhar mensagens de apoio e fortalecimento às 
                campanhas de Maio Amarelo, que é sobre a segurança no trânsito, Outubro Rosa, 
                Novembro Azul e preservação do Meio Ambiente.
              </p>
            </div>
          </div>
        </div>
        </VisibilitySensor>
    )
}
export default FleetSix;