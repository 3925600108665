import Navbar from "../../components/Navbar";
import MediaButtons from "../../components/MediaButtons";
import ButtonTop from "../../components/ButtonTop";
import Footer from "../../components/Footer";
import CookiesAccept from "../../components/CookiesAccept";
import HomeHero from "./components/HomeHero";
import HomeWelcome from "./components/HomeWelcome";
import HomeServices from "./components/HomeServices";
import HomeMap from "./components/HomeMap";
import HomeCertifications from "./components/HomeCertifications";
import HomeFleet from "./components/HomeFleet";
import HomeOds from "./components/HomeOds";
import HomeCareer from "./components/HomeCareer";
import HomeFeed from "./components/HomeFeed";
import HomeBlog from "./components/HomeBlog";
import HomeVideo from "./components/HomeVideo";

function Home() {
  return (
    <div id="home">
      <Navbar></Navbar>
      <MediaButtons></MediaButtons>
      <ButtonTop></ButtonTop>
      <CookiesAccept></CookiesAccept>
      <HomeHero></HomeHero>
      <HomeWelcome></HomeWelcome>
      <HomeVideo></HomeVideo>
      <HomeServices></HomeServices>
      <HomeMap></HomeMap>
      <HomeCertifications></HomeCertifications>
      <HomeFleet></HomeFleet>
      <HomeOds></HomeOds>
      <HomeCareer></HomeCareer>
      <HomeFeed></HomeFeed>
      <HomeBlog></HomeBlog>
      <Footer></Footer>
    </div>
  );
}

export default Home;
