import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import stars from "../../../assets/img/esg/esgFive/stars.webp";
import img1 from "../../../assets/img/esg/esgFive/img1.webp";
import img2 from "../../../assets/img/esg/esgFive/img2.webp";
import img3 from "../../../assets/img/esg/esgFive/img3.webp";
import img4 from "../../../assets/img/esg/esgFive/img4.webp";
import img5 from "../../../assets/img/esg/esgFive/img5.webp";
import thumb from "../../../assets/img/esg/esgFive/thumb.webp";
import video from "../../../assets/img/esg/esgFive/video.mp4";
import arrowRight from "../../../assets/img/components/arrow-right.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";
import phone from "../../../assets/img/esg/esgFive/phone.webp";

const breakpoints = {
    1050:{
      slidesPerView: 2,
    },
    900:{
      slidesPerView: 1,
    },
    769:{
      slidesPerView: 1,
    },
    700:{
        slidesPerView: 1,
    },
    500:{
        slidesPerView: 2,
    },
    0:{
      slidesPerView: 1,
    }
  }

function EsgFive(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
        <div id="esgFive" className={`esgFive ${isVisible ? 'visible' : ''}`}>
            <div className={`esgFiveTitle ${isVisible ? 'visible' : ''}`}>
                <h1 className={`${isVisible ? 'visible' : ''}`}>Programa Pellets Zero - OCS</h1>
                <img className={`${isVisible ? 'visible' : ''}`} src={stars} alt="" width={'150px'} />
                <p className={`${isVisible ? 'visible' : ''}`}>
                    Conscientes do impacto ambiental potencial dos polímeros, implementamos o programa Pellets Zero e 
                    desenvolvemos o Programa Resinas que Transformam. Este programa é dedicado a transformar esses materiais 
                    em brinquedos para crianças. Comprometidos com a sustentabilidade, esta iniciativa não só aborda a poluição 
                    potencial associada aos pellets, mas também promove a logística reversa de forma criativa, proporcionando 
                    diversão segura e ecologicamente correta para as crianças.
                </p>
            </div>
            <div className={`esgFiveContent ${isVisible ? "visible" : ""}`}>
                <Swiper
                    className={`${isVisible ? "visible" : ""}`}
                    spaceBetween={0}
                    modules={[Navigation, Autoplay]}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
/*                     autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                      }} */
                    speed={1000}
                    keyboard={true}
                    loop={true}
                    breakpoints={breakpoints}
                    slidesPerView={1}
                >
                    <div className="swiper-button-prev">
                        <img src={arrowLeft} width={"35px"} alt="" />
                    </div>
                    <SwiperSlide>
                        <div className={`card card1 ${isVisible ? 'visible' : ''}`}>
                            <img src={img1} alt="" width={'300px'}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card2 ${isVisible ? 'visible' : ''}`}>
                            <img src={img2} alt="" width={'300px'}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card3 ${isVisible ? 'visible' : ''}`}>
                            <img src={img3} alt="" width={'300px'}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card4 ${isVisible ? 'visible' : ''}`}>
                            <img src={img4} alt="" width={'300px'}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card5 ${isVisible ? 'visible' : ''}`}>
                            <img src={img5} alt="" width={'300px'}/>
                        </div>
                    </SwiperSlide>
                    <div className="swiper-button-next">
                        <img src={arrowRight} width={"35px"} alt="" />
                    </div>
                </Swiper>
                <div className="video">
                    <img className="phone" src={phone} alt="" />
                    <video
                        className={`${isVisible ? "visible" : ""}`}
                        src={video}
                        poster={thumb}
                        controls
                    />
                </div>
            </div>
        </div>
        </VisibilitySensor>
    )
}

export default EsgFive;