import React from "react";

import Navbar from "../../components/NavbarWhite";
import MediaButtons from "../../components/MediaButtons";
import ButtonTop from "../../components/ButtonTop";
import Footer from "../../components/Footer";
import CookiesAccept from "../../components/CookiesAccept";

import CareerOne from "./components/CareerOne";
import CareerTwo from "./components/CareerTwo";
import CareerThree from "./components/CareerThree";
import CareerFour from "./components/CareerFour";

function Career() {
  return (
    <div id="career">
      <Navbar />
      <ButtonTop></ButtonTop>
      <MediaButtons />
      <CookiesAccept></CookiesAccept>
      <CareerOne />
      <CareerTwo />
      <CareerThree/>
      <CareerFour/>
      <Footer />
    </div>
  );
}

export default Career;
