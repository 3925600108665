import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import mais from "../assets/img/mediaButtons/mais.webp";
import youtube from "../assets/img/mediaButtons/youtube.webp";
import insta from "../assets/img/mediaButtons/logotipo-do-instagram.webp";
import whats from "../assets/img/mediaButtons/whatsapp.webp";
import linkedin from "../assets/img/mediaButtons/linkedin.webp";
import face from "../assets/img/mediaButtons/facebook.webp";

function MediaButtons() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className={`mediaButtons ${isVisible ? "visible" : ""}`}>
        <button>
          <img
            className="mais"
            src={mais}
            alt=""
            width={"70px"}
            height={"auto"}
          />
        </button>
        <div className="youtube link">
          <a
            href="https://www.youtube.com/@GHSolucionadorLogistico"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="logotipo do youtube" width={"25px"} />
          </a>
        </div>
        <div className="insta link">
          <a
            href="https://www.instagram.com/ghsolucionadorlogistico"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="logotipo do instagram" width={"25px"} />
          </a>
        </div>
        <div className="whats link">
          <a
            href="https://wa.me/5547992204104?text=Olá%2C%0A%0AEstou%20entrando%20em%20contato%20pelo%20site%20da%20GH%20Solucionador%20Logístico.%20Poderia%20me%20ajudar%2C%20por%20favor%3F"
            target="_blank"
            rel="noreferrer"
          >
            <img src={whats} alt="logotipo do whatsapp" width={"25px"} />
          </a>
        </div>
        <div className="linkedin link">
          <a
            href="https://www.linkedin.com/company/ghtransporte/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="logotipo do linkedin" width={"25px"} />
          </a>
        </div>
        <div className="face link">
          <a
            href="https://www.facebook.com/GHSolucionadorLogistico/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={face} alt="logotipo do facebook" width={"25px"} />
          </a>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default MediaButtons;
