import React, { useState } from 'react';
import Cookies from 'js-cookie';

function CookiesAccept(){

    const [consent, setConsent] = useState(!Cookies.get('cookieConsent'));

    const handleConsent = () => {
      Cookies.set('cookieConsent', true, { expires: 365 * 10 });
      console.log(Cookies.get());
      setConsent(false);
    };
  
    if (!consent) {
      return null;
    }
    
    return(
        <div className="cookies">
            <p>Este site usa cookies para garantir a melhor experiência possível. Ao continuar a navegar, você concorda com o uso de cookies.</p>
            <button onClick={handleConsent}>Concordo</button>
        </div>
    )
}
export default CookiesAccept;