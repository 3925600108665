import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/esg/esgEight/img1.webp";

function EsgEight() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div id="esgEight" className={`esgEight ${isVisible ? "visible" : ""}`}>
        <div className="esgEightTitle">
          <h1 className={` ${isVisible ? "visible" : ""}`}>
            Indicador de Emissões GEE
          </h1>
          <p className={` ${isVisible ? "visible" : ""}`}>
            A GH adota uma abordagem meticulosa no monitoramento de todas as
            suas operações. Equipamentos embarcados em sua frota de caminhões
            coletam dados essenciais, incluindo distância percorrida e consumo
            de combustível, através da telemetria. Esses dados, combinados com
            informações de viagem como o peso transportado, são utilizados para
            calcular uma fórmula personalizada, levando em consideração o tipo e
            quantidade de combustível utilizado por cada veículo. A partir
            desses cálculos, são geradas informações sobre as emissões de Gases
            de Efeito Estufa (GEE). Esses dados são disponibilizados mensalmente
            em relatórios de desempenho, que podem ser filtrados de acordo com
            as preferências de cada cliente
          </p>
        </div>
        <div className="cards">
          <div className={`card1 card ${isVisible ? "visible" : ""}`}>
            <img src={img1} alt="" width={"250px"} />
            <div className="text">
              <h3>tCO₂e/km</h3>
              <p> 0,77834</p>
              <h5>2021</h5>
            </div>
          </div>
          <div className={`card2 card ${isVisible ? "visible" : ""}`}>
            <img src={img1} alt="" width={"250px"} />
            <div className="text">
              <h3>tCO₂e/km</h3>
              <p> 0,605425</p>
              <h5>2022</h5>
            </div>
          </div>
          <div className={`card3 card ${isVisible ? "visible" : ""}`}>
            <img src={img1} alt="" width={"250px"} />
            <div className="text">
              <h3>tCO₂e/km</h3>
              <p>0,67975</p>
              <h5>2023</h5>
            </div>
          </div>
          {/* <div className={`card4 card ${isVisible ? "visible" : ""}`}>
            <img src={img1} alt="" width={"250px"} />
            <div className="text">
              <h3>Lorem ipsum</h3>
              <p>10</p>
            </div>
          </div> */}
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default EsgEight;
