import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import arrowRight from "../../../assets/img/components/arrow-right.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";

import img1 from "../../../assets/img/about/aboutThree/card1.webp";
import img2 from "../../../assets/img/about/aboutThree/card1.webp";
import truck from "../../../assets/img/about/aboutThree/truck.webp";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

function AboutThree() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const [isClicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(!isClicked);
  };

  const breakpoints = {
    1400: {
      slidesPerView: 4,
    },
    1000: {
      effect: "none",
      slidesPerView: 3,
    },
    700: {
      effect: "none",
      slidesPerView: 2,
    },
    0: {
      effect: "none",
      slidesPerView: 1,
    },
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div className={`aboutThree ${isVisible ? "visible" : ""}`}>
        <div className="aboutThreeTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Uma história de inovação, compromisso e crescimento
          </h1>
        </div>
        <Swiper
          breakpoints={breakpoints}
          modules={[Navigation]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          slidesPerView={2}
          speed={200}
        >
          <div className="swiper-button-prev">
            <img src={arrowLeft} width={"35px"} alt="" />
          </div>
          <img
            className={`truck ${isClicked ? "truckActive" : ""}`}
            src={truck}
            alt=""
            width={"150px"}
          />
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <h2>2011</h2>
              <div className="cardContent">
                <h3>Início das operações da GH</h3>
                <p>
                  Neste ano, a GH deu seus primeiros passos no mundo logístico,
                  marcando o início de uma história repleta de desafios e
                  conquistas.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card2 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2012</h2>
                <h3>Aquisição do primeiro veículo frota própria</h3>
                <p>
                  Com a chegada do Scania 55 anos, a GH deu um passo importante
                  em direção à independência logística, escolhendo a cor azul
                  ultramarino para sua frota e consolidando sua identidade.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card3 card ${isVisible ? "visible" : ""}`}>
              <h2>2013</h2>
              <div className="cardContent">
                <h3>Primeira mudança de endereço da GH</h3>
                <p>
                  Com nove membros na equipe, sendo 3 sócios, a GH expandiu suas
                  operações e se estabeleceu em um novo local, marcando um
                  período de crescimento e consolidação.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card4 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2014</h2>
                <h3> Batemos a meta do primeiro milhão</h3>
                <p>
                  Um marco significativo foi alcançado, demonstrando a
                  eficiência e o comprometimento da GH em oferecer soluções
                  logísticas diferenciadas.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card5 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2015</h2>
                <h3>A frota própria atingia 10 conjuntos</h3>
                <p>
                  Com um crescimento constante, a GH fortaleceu sua frota para
                  atender às demandas crescentes do mercado, garantindo rapidez
                  e qualidade em cada entrega.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card6 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2016</h2>
                <h3>Certificação SASSMAQ e ISO 9001</h3>
                <p>
                  O reconhecimento internacional da qualidade e segurança das
                  operações da GH fortaleceu sua posição como uma empresa modelo
                  no setor logístico.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card7 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2017</h2>
                <h3>Aquisição de uma nova frota de veículos Scania</h3>
                <p>
                  Investindo no aumento da capacidade operacional, a GH expandiu
                  suas operações, garantindo eficiência e confiabilidade em cada
                  trajeto. Em apenas 6 anos, o investimento foi de 10 milhões na
                  nova frota.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card8 card ${isVisible ? "visible" : ""}`}>
              <h2>2018</h2>
              <div className="cardContent">
                <h3>Evolução para uma nova sede e certificações ISO</h3>
                <p>
                  Com uma nova sede e a ampliação das operações para incluir
                  armazenagem de mercadorias, a GH reforçou seu compromisso com
                  o desenvolvimento e a sustentabilidade, o que levou a empresa
                  a receber os certificados ISO 9001, ISO 14001 e ISO 45001.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card9 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2019</h2>
                <h3>Implantação de um novo ERP e evolução tecnológica</h3>
                <p>
                  Com investimentos em tecnologia e automação, a GH otimizou
                  seus processos, garantindo mais agilidade em cada etapa da
                  cadeia logística.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card10 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2020</h2>
                <h3>Expansão para nova unidade em Itajaí</h3>
                <p>
                  Com a inauguração de uma nova unidade, a GH ampliou sua
                  capacidade de armazenagem em 100%, fortalecendo sua presença e
                  oferecendo soluções logísticas ainda mais abrangentes.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card11 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2021</h2>
                <h3>
                  Reconhecimento como uma das melhores empresas para se
                  trabalhar
                </h3>
                <p>
                  Com uma equipe de aproximadamente 140 colaboradores, a GH foi
                  reconhecida como um excelente lugar para se trabalhar pelo
                  GPTW, destacando-se pelo ambiente colaborativo e oportunidades
                  de crescimento.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card12 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2022</h2>
                <h3>Monitoramento por câmeras em 100% da frota própria</h3>
                <p>
                  Investindo em segurança e controle, a GH garantiu a proteção
                  de sua frota e o monitoramento eficaz de suas operações.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card13 card ${isVisible ? "visible" : ""}`}>
              <div className="cardContent">
                <h2>2023</h2>
                <h3>Início das operações com caminhões movidos a GNV</h3>
                <p>
                  Comprometida com a sustentabilidade, a GH adotou caminhões
                  movidos a GNV em suas operações interestaduais, promovendo uma
                  logística mais ecológica e eficiente.
                </p>
              </div>
            </div>
          </SwiperSlide>

          <div className="swiper-button-next" onClick={handleButtonClick}>
            <img src={arrowRight} width={"35px"} alt="" />
          </div>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}

export default AboutThree;
