import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

import img1 from '../../../assets/img/about/aboutSeven/img1.webp'
import img2 from '../../../assets/img/about/aboutSeven/img2.webp'
import img3 from '../../../assets/img/about/aboutSeven/img3.webp'
import img4 from '../../../assets/img/about/aboutSeven/img4.webp'
import img5 from '../../../assets/img/about/aboutSeven/img5.webp'

import arrowRight from '../../../assets/img/components/arrow-right.webp';
import arrowLeft from '../../../assets/img/components/arrow-left.webp';

function AboutSeven(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility={true} minTopValue={300} >
        <div className={`aboutSeven ${isVisible ? 'visible' : ''}`}>
            <div className="aboutSevenTitle">
                <h1 className={`${isVisible ? 'visible' : ''}`}>Galeria de fotos</h1>
            </div>
            <Swiper
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }}
                slidesPerView={3}
                spaceBetween={0}
                loop
                modules={[Navigation]}
                className={`${isVisible ? 'visible' : ''}`}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                      },
                    500: {
                        slidesPerView: 2,
                      },
                    768: {
                      slidesPerView: 3,
                    }
                  }}
            >
                <div className="swiper-button-prev"><img src={arrowLeft} width={'35px'} alt="" /></div>
                <SwiperSlide>
                    <div className="card1 card">
                        <img src={img3} alt="" />
                        <img src={img4} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card2 card">
                        <img src={img1} alt="" />
                        <img src={img2} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card3 card">
                        <img src={img5} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card1 card">
                        <img src={img3} alt="" />
                        <img src={img4} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card2 card">
                        <img src={img1} alt="" />
                        <img src={img2} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card3 card">
                        <img src={img5} alt="" />
                    </div>
                </SwiperSlide>
                <div className="swiper-button-next"><img src={arrowRight} width={'35px'} alt="" /></div>
            </Swiper>
        </div>
        </VisibilitySensor>
    )
}

export default AboutSeven;