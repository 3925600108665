import React from "react";
import {
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCardImage,
} from "@coreui/react-pro";
import postServices from '../../../services/post'
import img from "../../../assets/img/blog/img-blog.webp";
import { useQuery } from "@tanstack/react-query";
import { useBlogContext } from "../contexts/BlogContext";
import { formatIsoDateToUTC } from "../../../utils/formatIsoDateToUTC";
import { limitCharacters } from "../../../utils/limitCharacters"
import { useNavigate } from "react-router-dom";

const CardPublicationSmall = ({ post }) => {

  const navigate = useNavigate()

  const { data: postImage } = useQuery({
    queryKey: [`image-post-${post.id}`],
    queryFn: () => postServices.getPostImage(post.image_path),
    stale: 18000000, //5h,
    refetchOnWindowFocus: false
  })


  return (
    <article id="card-publication">
      <div className="container d-flex gap-5" onClick={() => navigate("/blog/post?id=" + post.id)} style={{ cursor: "pointer" }}>
        <div className="cards">
          <CCard className="card-small">
            {postImage?.data?.file.type !== 'text/html'
              ? (<CCardImage orientation="top" src={postImage?.data?.url} width={20} />)
              : (<CCardImage orientation="top" src={img} width={20} />)
            }
            <CCardBody>
              <div className="card-align">
                <CCardText>
                  {formatIsoDateToUTC(post.created_at)}
                </CCardText>
              </div>
              <div className="card-title">
                <CCardTitle>{post.title}</CCardTitle>
              </div>
              <div className="card-description">
                <CCardText >{limitCharacters(post.description.replace(/(<([^>]+)>)/gi, ''), 125)}</CCardText>
              </div>
            </CCardBody>
          </CCard>
        </div>
      </div>
    </article>
  )
}

export default React.memo(CardPublicationSmall);
