import React from "react";
import {
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCardImage,
} from "@coreui/react-pro";
import { useBlogContext } from "../contexts/BlogContext";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import postServices from '../../../services/post'
import img from '../../../assets/img/blog/img-blog.webp'
import { formatIsoDateToUTC } from "../../../utils/formatIsoDateToUTC";

function SectionVisiblePubli() {

  const { postToShow } = useBlogContext()
  const navigate = useNavigate()

  if (!postToShow) {
    navigate("/blog")
  }


  const { isPending, data: postImage } = useQuery({
    queryKey: [`image-post-${postToShow?.id}`],
    queryFn: () => postServices.getPostImage(postToShow?.image_path),
    stale: 18000000, //5h,
    refetchOnWindowFocus: false
  })


  return (
    <div id="section-visible-publi">
      <CCard>
        <CCardBody className="container-sm">
          <CCardText className="align-item-title">
            <small className="text-medium-emphasis">
              {formatIsoDateToUTC(postToShow?.created_at)}
            </small>
            <small className="text-medium-emphasis">
              Atualizado: {formatIsoDateToUTC(postToShow?.updated_at)}
            </small>
          </CCardText>
          {postImage?.data?.file.type !== 'text/html'
              ? (<CCardImage orientation="top" src={postImage?.data?.url} width={20} />)
              : (<CCardImage orientation="top" src={img} width={20} />)
            }
          <CCardTitle>
            {postToShow?.title}
          </CCardTitle>
          <CCardText dangerouslySetInnerHTML={{ __html: postToShow?.description }}>

          </CCardText>
          {/* <CCardText>
            <small className="text-visible">170 visualizações</small>
          </CCardText> */}
        </CCardBody>
      </CCard>
    </div>
  );
}

export default SectionVisiblePubli;
