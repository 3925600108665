import React, { useState } from "react";
import { CButton, CForm, CFormInput, CFormLabel } from "@coreui/react-pro";
import VisibilitySensor from "react-visibility-sensor";
import { useAlert } from "../../../contexts/AlertContext";
import { subscribe } from "../../../services/subscribe";

function Newsletter() {

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const defaultFormData = {
    email: '',
    isResponseLoading: false
  }

  const [formData, setFormData] = useState(defaultFormData)
  const { showAlert, hideAlert } = useAlert()

  const handleSubscribe = async () => {

    hideAlert()

    setFormData(prev => ({ ...prev, isResponseLoading: true }))

    if (!formData.email.trim() || !formData.email.includes('@') || !formData.email.includes('.com')) {
      showAlert('Insira um email válido')
      setFormData(prev => ({ ...prev, isResponseLoading: false }))
      return
    }

    const res = await subscribe(formData.email)

    showAlert(res.message, res.success ? 'success' : 'error')

    setFormData(defaultFormData)
  }

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
        <div className={`newsletter-bg ${isVisible ? 'visible' : ''}`}>
          <div className="newsletter">
          <h1 className={`${isVisible ? 'visible' : ''}`}>Inscreva-se na <span>GHNews</span></h1>
          <div className={`line ${isVisible ? 'visible' : ''}`}></div>
          <CForm className={`${isVisible ? 'visible' : ''}`}>
            <CFormLabel htmlFor="emailinput">E-mail:</CFormLabel>
            <CFormInput
              id="emailinput"
              type="email"
              className="me-2"
              placeholder="exemplo@dominio.com"
              value={formData.email}
              onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
            />
            <CButton
              className="mt-2"
              disabled={formData.isResponseLoading}
              onClick={handleSubscribe}
            >
              {formData.isResponseLoading ? 'Enviando...' : 'Enviar'}
            </CButton>
          </CForm>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default Newsletter;
