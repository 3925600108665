import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/warehouseStructure/warehouseFive/warehouseFive.webp";

function WarehouseFive(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
            <div className="warehouseFive">
              <img className={`${isVisible ? 'visible' : ''}`} src={img1} alt="" width={'200px'} />
              <div className="warehouseFiveText">
                <h1 className={`${isVisible ? 'visible' : ''}`}>Otimizando a Logística com Coletores de Dados Portáteis</h1>
                <p className={`${isVisible ? 'visible' : ''}`}>
                  Para impulsionar a eficiência de nossa operação e assegurar o fluxo logístico "just in time", contamos com 
                  coletores de dados portáteis. Esses dispositivos são projetados para capturar códigos de barras 1D e 2D, 
                  oferecendo uma integração direta com o nosso Sistema de Gerenciamento de Armazém (WMS). Através dessa integração, 
                  realizamos verificações precisas de cargas, identificação de localização e inspeções, garantindo uma execução ágil 
                  e precisa de nossas operações logísticas.
                </p>
              </div>
            </div>
        </VisibilitySensor>
    )
}
export default WarehouseFive;