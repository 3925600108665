import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/warehouseStructure/warehouseFour/warehouseFour.webp";
import seta from "../../../assets/img/components/seta2.webp";

function WarehouseFour(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
            <div>
              <h1 className={`warehouseTitle ${isVisible ? 'visible' : ''}`}>Nosso armazém é adaptado com <span>alta tecnologia</span></h1>
              <div className="warehouseFour">
                <h1 className={`${isVisible ? 'visible' : ''}`}> Integração de Sistemas</h1>
                <p className={`${isVisible ? 'visible' : ''}`}>
                  Contamos com uma equipe especializada em TI que possibilita a integração direta do nosso 
                  sistema WMS com o ERP do cliente por meio de Integração via Web Service 
                  <br />
                  Essa tecnologia permite:
                </p>
                <ul className={`${isVisible ? 'visible' : ''}`}>
                  <li><img src={seta} className="seta" alt=""/> Integração dos pedidos de venda (B2B e B2C)</li>
                  <li><img src={seta} className="seta" alt=""/> Cadastro de produtos</li>
                  <li><img src={seta} className="seta" alt=""/> Envio de NF de recebimento</li>
                  <li><img src={seta} className="seta" alt=""/> Acompanhamento em tempo real das etapas do processo de recebimento e expedição</li>
                  <li><img src={seta} className="seta" alt=""/> Visualização do estoque em tempo real</li>
                  <li><img src={seta} className="seta" alt=""/> Envio de faturas de serviços</li>
                  <li><img src={seta} className="seta" alt=""/> Acompanhamento das ocorrências operacionais</li>
                  <li><img src={seta} className="seta" alt=""/> Enviar NF de recebimento</li>
                  <li><img src={seta} className="seta" alt=""/> Visualizar o follow-up em tempo real sobre as etapas do processo de recebimento e expedição</li>
                  <li><img src={seta} className="seta" alt=""/> Visualizar o estoque em tempo real</li>
                  <li><img src={seta} className="seta" alt=""/> Enviar de fatura de serviços</li>
                </ul>
                <img className={`${isVisible ? 'visible' : ''}`} src={img1} alt="" width={'200px'}/>
              </div>
          </div>
        </VisibilitySensor>
    )
}
export default WarehouseFour;