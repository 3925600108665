import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay } from 'swiper/modules';

import cardBg from '../../../assets/img/about/aboutFour/image2.webp';

function AboutFour(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <div className="aboutFour">
            <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
            <div>
                <div className="aboutFourTitle">
                    <h1 className={`${isVisible ? 'visible' : ''}`}>Conheça nossa <br /> cultura organizacional</h1>
                </div>
                <div className="aboutFourContent1">
                    <div className={`card1 card ${isVisible ? 'visible' : ''}`}>
                        <img src={cardBg} alt="" width={'300px'}/>
                        <div className="cardText">
                            <h2>Missão</h2>
                            <p>Ser o melhor caminho para suas conquistas.</p>
                        </div>
                    </div>
                    <div className={`card2 card ${isVisible ? 'visible' : ''}`}>
                        <img src={cardBg} alt="" width={'150px'}/>
                        <div className="cardText">
                            <h2>Valores</h2>
                        </div>
                    </div>
                    <div className={`card3 card ${isVisible ? 'visible' : ''}`}>
                        <img src={cardBg} alt="" width={'300px'}/>
                        <div className="cardText">
                            <h2>Visão</h2>
                            <p>Queremos ser reconhecidos como o melhor solucionador logístico do Brasil.</p>
                        </div>
                    </div>
                </div>
                <Swiper
                    breakpoints={{
                        0:{
                            slidesPerView: 1,
                        },
                        768:{
                            slidesPerView: 2,
                        },
                        1000:{
                            slidesPerView: 3,
                        },
                        1600:{
                            slidesPerView: 4,
                        }
                    }}
                    slidesPerView={4}
                    spaceBetween={30}
                    className={`${isVisible ? 'visible' : ''}`}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                >
                    <SwiperSlide>
                        <div className={`card card1 ${isVisible ? 'visible' : ''}`}>
                            <h2>Cordialidade</h2>
                            <div className="underline"></div>
                            <p>Nos colocamos no lugar do outro, o que importa para o próximo importa para nós, 
                                estamos  aqui para solucionar problemas que estão ao nosso alcance e entender 
                                as necessidade de todos os interessados.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card2 ${isVisible ? 'visible' : ''}`}>
                            <h2>Mais com menos</h2>
                            <div className="underline"></div>
                            <p>
                                Queremos sempre otimizar recursos, aumentar produtividade e resultados sem 
                                onerar custos financeiros, desgastes desnecessários, tempo, recursos naturais 
                                e qualidade. Sempre pensamos em fazer com menos e entregar mais.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card3 ${isVisible ? 'visible' : ''}`}>
                            <h2>Paixão pelo que fazemos</h2>
                            <div className="underline"></div>
                            <p>
                                Nossa energia vem da paixão por superar expectativas e
                                entregar resultados extraordinários. Cada projeto, cada
                                entrega, cada desafio é abraçado com entusiasmo e
                                dedicação para sermos os melhores no que fazemos.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card4 ${isVisible ? 'visible' : ''}`}>
                            <h2>Meritocracia</h2>
                            <div className="underline"></div>
                            <p>
                                Na GH, o esforço e a dedicação não passam despercebidos.
                                Valorizamos e recompensamos aqueles que vão além,
                                demonstrando comprometimento, atingindo metas e
                                contribuindo ativamente para o sucesso coletivo.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card5 ${isVisible ? 'visible' : ''}`}>
                            <h2>Evolução contínua</h2>
                            <div className="underline"></div>
                            <p>
                                Nunca nos contentamos com o status quo. Estamos
                                constantemente em busca de formas de inovar, adaptar e
                                aprimorar nossos processos, serviços e soluções, visando a
                                excelência em cada passo.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={`card card6 ${isVisible ? 'visible' : ''}`}>
                            <h2>Compromisso Socioambiental e ética</h2>
                            <div className="underline"></div>
                            <p>
                                Na GH, agimos com responsabilidade socioambiental e ética
                                empresarial em todas as nossas atividades. Buscamos a
                                excelência enquanto promovemos práticas sustentáveis,
                                respeitando as leis e superando expectativas para um
                                futuro melhor para todos.
                            </p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            </VisibilitySensor>
        </div>
    )
}

export default AboutFour;