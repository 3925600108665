import React, { useEffect, useState } from "react";
import blog1 from "../assets/img/home/blog/blog1.webp";
import postServices from "../services/post";

const PostCard = ({ post }) => {
  const [postImage, setPostImage] = useState(null);

  useEffect(() => {
    async function getImage() {
      const res = await postServices.getPostImage(post?.image_path);
      setPostImage(res);
    }

    getImage();
  }, [post.image_path]);

  return (
    <a href="/blog">
      <div className={`blogCard card1 ${post.isVisible ? "visible" : ""}`}>
        {postImage?.data?.file.type !== "text/html" ? (
          <img alt={post.title} src={postImage?.data?.url} />
        ) : (
          <img alt={post.title} src={blog1} />
        )}

        <h1>{post.title}</h1>
        <p>{post.description.replace(/(<([^>]+)>)/gi, "")}</p>
      </div>
    </a>
  );
};

export default React.memo(PostCard);
