import NavbarWhite from "../../components/NavbarWhite";
import MediaButtons from "../../components/MediaButtons";
import Footer from "../../components/Footer";
import ButtonTop from "../../components/ButtonTop";
import CookiesAccept from "../../components/CookiesAccept";

import ContactOne from "./components/ContactOne";
import ContactThree from "./components/ContactThree";

function Contact() {
  return (
    <div id="contact">
      <NavbarWhite></NavbarWhite>
      <ButtonTop></ButtonTop>
      <MediaButtons></MediaButtons>
      <CookiesAccept></CookiesAccept>
      <ContactOne></ContactOne>
      <ContactThree></ContactThree>
      <Footer></Footer>
    </div>
  );
}

export default Contact;
