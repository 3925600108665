import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

function EsgTwo(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
            <div id="esgTwo" className={`esgTwo ${isVisible ? 'visible' : ''}`}>
                <iframe 
                    className={`${isVisible ? 'visible' : ''}`}
                    width="900" 
                    height="400" 
                    src="https://www.youtube.com/embed/NqPLG8utryY?si=_SycKKo5kaXL8oDA" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                </iframe>
            </div>
        </VisibilitySensor>
    )
}

export default EsgTwo;