import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/warehouseStructure/warehouseSix/warehouseSix.webp";

function WarehouseSix(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
            <div className="warehouseSix">
              <h1 className={`${isVisible ? 'visible' : ''}`}>Monitoramento de Temperatura e Umidade</h1>
              <p className={`${isVisible ? 'visible' : ''}`}>
                Em conformidade com as diretrizes da Anvisa e possuindo autorizações AFE para medicamentos, saneantes, 
                cosméticos e correlatos, nossas unidades de armazenamento são projetadas para atender aos mais altos 
                padrões de qualidade. Como parte desse compromisso, implementamos um sistema de monitoramento de 
                temperatura e umidade em nossos armazéns.
                <br />
                Nossos dispositivos especializados captam dados de temperatura e umidade a cada 10 minutos, garantindo 
                um controle rigoroso do ambiente de armazenamento. Esses dados estão prontamente disponíveis para 
                consulta pelos nossos clientes, proporcionando transparência e garantindo a integridade dos produtos 
                durante todo o processo de armazenamento e expedição.
              </p>
              <img className={`${isVisible ? 'visible' : ''}`} src={img1} alt="" width={'200px'} />
            </div>
        </VisibilitySensor>
    )
}
export default WarehouseSix;