import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCardImage,
} from "@coreui/react-pro";
import { useQuery } from "@tanstack/react-query";
import postServices from 'src/services/post'
import img from 'src/assets/img/blog/img-blog.webp'
import { formatIsoDateToUTC } from "src/utils/formatIsoDateToUTC";
import SwiperCard from "./components/SwiperCard";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "src/components/LoadingSpinner";
import ScrollToTop from "src/components/ScrollToTop";

const Post = () => {

  const [urlParams] = useSearchParams();

  const { isPending: isFetchingImage, data: postImage, refetch: fetchPostImage } = useQuery({
    queryKey: [`image-post-${urlParams.get('id')}`],
    queryFn: () => postServices.getPostImage(post?.image_path),
    stale: 18000000, //5h,
    refetchOnWindowFocus: false,
    enabled: false
  })

  const { data: post, isPending: isFetchingPost } = useQuery({
    queryKey: [`post-${urlParams.get('id')}`],
    queryFn: () => postServices.getPostById(urlParams.get('id')),
    stale: 18000000, //5h,
    refetchOnWindowFocus: false,
  })


  useEffect(() => {

    if (!isFetchingPost) {
      fetchPostImage()
    }

  }, [post])


  return (
    <>
      <ScrollToTop />

      <div id="section-visible-publi">
        {(isFetchingPost || isFetchingImage)
          ? (<LoadingSpinner />)
          : (<CCard>
            <CCardBody className="container-sm">
              <CCardText className="align-item-title">
                <small className="text-medium-emphasis">
                  {formatIsoDateToUTC(post?.created_at)}
                </small>
                <small className="text-medium-emphasis">
                  Atualizado: {formatIsoDateToUTC(post?.updated_at)}
                </small>
              </CCardText>
              {postImage?.data?.file.type !== 'text/html'
                ? (<CCardImage orientation="top" src={postImage?.data?.url} width={20} />)
                : (<CCardImage orientation="top" src={img} width={20} />)
              }
              <CCardTitle>
                {post?.title}
              </CCardTitle>
              <CCardText dangerouslySetInnerHTML={{ __html: post?.description }}>

              </CCardText>
            </CCardBody>
          </CCard>)
        }
      </div>
      <h1 className="tittle-visible-publi container">Posts recentes</h1>
      <SwiperCard />
    </>
  )
}

export default React.memo(Post)
