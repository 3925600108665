import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay } from 'swiper/modules';

import 'swiper/css';

import img1 from "../../../assets/img/home/certifications/card1.webp";
import img2 from "../../../assets/img/home/certifications/card3.webp";
import img3 from "../../../assets/img/home/certifications/card4.webp";
import img4 from "../../../assets/img/home/certifications/card5.webp";

function EsgEleven(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    const breakpoints = {
        1200: {
            slidesPerView: 4,
        },
        1000: {
            spaceBetween: 20,
        },
        900: {
            slidesPerView: 3,
        },
        700: {
            slidesPerView: 2,
        },
        0: {
            slidesPerView: 1
        }
    }

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
            <div id="esgEleven" className={`esgEleven ${isVisible ? 'visible' : ''}`}>
                <div className="esgElevenTitle">
                    <h1 className={`${isVisible ? 'visible' : ''}`}>A Construção de uma Cultura Organizacional Resiliente <br /> <span>Saúde, Segurança, Meio Ambiente e Qualidade</span></h1>
                    <p className={`${isVisible ? 'visible' : ''}`}>
                        Para garantir a aderência contínua às normas implementadas, o departamento SSMAQ-S desempenha um papel crucial na gestão abrangente de 
                        todos os aspectos relacionados à Saúde, Segurança, Meio Ambiente, Qualidade e Responsabilidade Social. Sua influência é fundamental para 
                        impulsionar nossa cultura organizacional e promover melhorias contínuas em nosso Sistema de Gestão Integrado (SGI).
                    </p>
                </div>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={40}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                    speed={500} 
                    loop={true}
                    modules={[Autoplay]}
                    breakpoints={breakpoints}
                    className={`${isVisible ? 'visible' : ''}`}
                >
                    <SwiperSlide>
                        <div className={`card1 card ${isVisible ? 'visible' : ''}`}>
                            <img src={img1} alt="" />
                            <p>
                                O SASSMAQ é uma certificação, sendo destinada aos transportadores de produtos químicos, onde somos certificados desde 2016.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>                  
                    <div className={`card1 card ${isVisible ? 'visible' : ''}`}>
                        <img src={img2} alt="" />
                        <p>
                            Desde 2016, nossa dedicação à excelência nos levou à certificação na NBR ISO 9001, uma norma que enfatiza o Sistema de Gestão da Qualidade, priorizando o foco no cliente.
                        </p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className={`card1 card ${isVisible ? 'visible' : ''}`}>
                        <img src={img3} alt="" />
                        <p>
                            Em 2018, demos um passo crucial ao implementar a NBR ISO 14001, concentrando nossos esforços no Sistema de Gestão Ambiental para promover práticas sustentáveis.
                        </p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className={`card1 card ${isVisible ? 'visible' : ''}`}>
                        <img src={img4} alt="" />
                        <p>
                            Impulsionados pelos avanços conquistados com a NBR ISO 14001, ainda em 2018, adotamos a ISO 45001 para fortalecer nosso compromisso com a saúde e segurança ocupacional de nossa equipe.
                        </p>
                    </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </VisibilitySensor>
    )
}

export default EsgEleven;