import React, { useState } from "react";

import VisibilitySensor from 'react-visibility-sensor';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';

import swiperImg1 from "../../../assets/img/home/hero/swiperImg1.webp";
import swiperImg2 from "../../../assets/img/home/hero/swiperImg2.webp";

import gptw from "../../../assets/img/home/hero/selo-large.webp";

import arrow from "../../../assets/img/components/buttonBlue.webp";
import arrow2 from "../../../assets/img/components/buttonBlue2.webp";
import arrow3 from "../../../assets/img/components/buttonBlue3.webp";

function HomeHero(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <section>
            <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
                <div className="homeHeroBg">
                    <div className="homeHero">
                        <div className={`gptw ${isVisible ? 'visible' : ''}`} >
                            <img src={gptw} alt="" width={'60px'} height={'102px'}/>
                        </div>
                        <Swiper
                            allowTouchMove= {false}
                            className="mySwiper swiperText"
                            modules={[Autoplay]}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            speed={1200}
                            loop
                            breakpoints={{
                                0:{
                                    direction: "horizontal"
                                },
                                769: {
                                    direction: "vertical",
                                },
                            }}
                        >
                            <SwiperSlide><h1 className={`${isVisible ? 'visible' : ''}`}>Conduzindo a logística do <br /> amanhã pelas estradas do hoje, <br /> com confiança e agilidade.</h1></SwiperSlide>
                            <SwiperSlide><h1 className={`${isVisible ? 'visible' : ''}`}>Nossos corredores de armazenagem <br /> combinam tecnologia avançada <br /> e sistemas inteligentes.</h1></SwiperSlide>
                        </Swiper>
                        <Swiper
                            allowTouchMove= {false}
                            direction={'vertical'}
                            className="mySwiper swiperImg"
                            modules={[Autoplay]}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            speed={1200}
                            loop
                            breakpoints={{
                                0:{
                                    direction: "horizontal"
                                },
                                769: {
                                    direction: "vertical",
                                },
                            }}
                        >
                            <SwiperSlide><img className={`${isVisible ? 'visible' : ''}`} src={swiperImg1} alt="" width={'550px'} height={'500px'} /></SwiperSlide>
                            <SwiperSlide><img className={`${isVisible ? 'visible' : ''}`} src={swiperImg2} alt="" width={'550px'} height={'500px'} /></SwiperSlide>
                        </Swiper>
                    </div>
                    <img className={`arrow1 ${isVisible ? 'visible' : ''}`} src={arrow} alt="" />
                    <img className={`arrow2 ${isVisible ? 'visible' : ''}`} src={arrow} alt="" />
                    <img className={`arrow3 ${isVisible ? 'visible' : ''}`} src={arrow3} alt="" />
                    <img className={`arrow4 ${isVisible ? 'visible' : ''}`} src={arrow3} alt="" />
                    <img className={`arrow5 ${isVisible ? 'visible' : ''}`}  src={arrow2} width={'100px'} alt="" />
                    <img className={`arrow6 ${isVisible ? 'visible' : ''}`} src={arrow2} width={'100px'} alt="" />
                    <img className={`arrow7 ${isVisible ? 'visible' : ''}`} src={arrow} alt="" />
                    <img className={`arrow8 ${isVisible ? 'visible' : ''}`} src={arrow} alt="" />
                </div>
            </VisibilitySensor>
        </section>
    )
}

export default HomeHero;