import React, { useState } from "react";
import { CButton, CForm, CFormInput, CFormLabel, CFormTextarea } from "@coreui/react-pro";

import VisibilitySensor from "react-visibility-sensor";
import { sendAnonymousReport } from "../../../services/sendAnonymousReport";
import { useAlert } from "../../../contexts/AlertContext";

function ReportsOne() {

  const defaultFormData = {
    type: "",
    local: "",
    description: "",

    isResponseLoading: false
  }


  const [formData, setFormData] = useState(defaultFormData)

  const {showAlert} = useAlert()

  const handleSendReport = async () => {


    setFormData(prev => ({ ...prev, isResponseLoading: true }))

    const res = await sendAnonymousReport(formData)

    if (res.success) {
      setFormData(defaultFormData)
    }

    setFormData(prev => ({ ...prev, isResponseLoading: false }))

    showAlert(res.message, res.success ? 'success': 'error')

  }


  return (
    <VisibilitySensor partialVisibility minTopValue={100}>
      <div className="reportsOne">
        <div className="reportsText">
          <h1>CANAL DE <span>DENÚNCIAS ANÔNIMAS</span></h1>
          <p className="subTitle">Abaixo você poderá descrever os acontecimentos que deseja relatar.</p>
          <div className="list">
            <p>É essencial adicionar o máximo de detalhes sobre a situação. Lembre-se de incluir:</p>
            <ul>
              <li>1 - O quê (descrição da situação)</li>
              <li>2 - Quem (nome das pessoas envolvidas, inclusive testemunhas)</li>
              <li>3 - Quando (data em que aconteceu, acontece ou acontecerá a situação)</li>
              <li>4 - Onde (Local do relato)</li>
              <li>5 - Por que (a causa ou motivo)</li>
              <li>6 - Provas (se elas existem e onde podem ser encontradas)</li>
            </ul>
          </div>
        </div>
        <CForm>
          <div>
            <CFormLabel>Tipo de relato:</CFormLabel>
            <CFormInput
              type="text"
              value={formData.type}
              maxLength={80}
              minLength={3}
              onChange={(event) => setFormData(prev => ({ ...prev, type: event.target.value }))}
              className="me-2"
              placeholder=""
            ></CFormInput>
            <CFormLabel>Local do relato:</CFormLabel>
            <CFormInput
              type="text"
              value={formData.local}
              autoComplete='off'
              maxLength={40}
              onChange={(event) => setFormData(prev => ({ ...prev, local: event.target.value }))}
              className="me-2"
              placeholder=""
            ></CFormInput>
            <CFormLabel className="mt-2">Descrição:</CFormLabel>
            <CFormTextarea
              type="text"
              value={formData.description}
              autoComplete='off'
              maxLength={300}
              minLength={25}
              rows={4}
              as="text-area"
              onChange={(event) => setFormData(prev => ({ ...prev, description: event.target.value }))}
              className="me-2 message"
              placeholder=""
            ></CFormTextarea>
            <CButton className="mt-4" onClick={handleSendReport} disabled={formData.isResponseLoading}>
              {formData.isResponseLoading ? 'Enviando...' : 'Enviar'}
            </CButton>
          </div>
        </CForm>
      </div>
    </VisibilitySensor>
  );
}

export default ReportsOne;
