import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

function ContactThree() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className="contactThree">
        <h1 className={`${isVisible ? "visible" : ""}`}>
          CANAL DE DENÚNCIAS ANÔNIMAS
        </h1>
        <p className={`${isVisible ? "visible" : ""}`}>
          Caso tenha identificado qualquer inconformidade em nosso atendimento,
          por favor, não hesite em nos enviar uma mensagem.
        </p>
        <a
          className={`${isVisible ? "visible" : ""}`}
          href="/anonymousReports"
          reloadDocument
        >
          <button>Denuncie agora</button>
        </a>
      </div>
    </VisibilitySensor>
  );
}
export default ContactThree;
