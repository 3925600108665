import React, { useState } from "react";

import img1 from "../../../assets/img/warehouseStructure/warehouseThree/warehouseThree-bg2.webp";

import VisibilitySensor from "react-visibility-sensor";

function WarehouseThree(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
          <div className={`warehouseThree ${isVisible ? 'visible' : ''}`}>
            <h1 className={`${isVisible ? 'visible' : ''}`}>A eficiência é potencializada quando os processos são orientados pela humanização.</h1>
            <p className={`${isVisible ? 'visible' : ''}`}>
              Através do nosso time de mãos treinadas e cuidadosas, entregamos 
              qualidade certificada pelo ISO seguindo todas as normas rígidas que 
              mantém o melhor em todos os serviços e processos de documentação, 
              garantindo segurança para todas as suas cargas.
            </p>
            <img className={`${isVisible ? 'visible' : ''}`} src={img1} alt="" />
          </div>
        </VisibilitySensor>
    )
}
export default WarehouseThree;