import ServicesOne from "./components/ServicesOne";
import ServicesTwo from "./components/ServicesTwo";
/* import ServicesThree from "./components/ServicesThree"; */
import ServicesFour from "./components/ServicesFour";
import ServicesFive from "./components/ServicesFive";

import Navbar from "../../components/NavbarWhite";
import Footer from "../../components/Footer";
import MediaButtons from "../../components/MediaButtons";
import ButtonTop from "../../components/ButtonTop";
import CookiesAccept from "../../components/CookiesAccept";

function Services() {
  return (
    <div id="services">
      <MediaButtons></MediaButtons>
      <ButtonTop></ButtonTop>
      <Navbar></Navbar>
      <CookiesAccept></CookiesAccept>
      <ServicesOne></ServicesOne>
      <ServicesTwo></ServicesTwo>
      <ServicesFour></ServicesFour>
      <ServicesFive></ServicesFive>
      <Footer></Footer>
    </div>
  );
}

export default Services;
