import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import { Swiper, SwiperSlide } from "swiper/react";

import arrowRight from "../../../assets/img/components/arrow-right.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";

import postServices from "../../../services/post";

import { useQuery } from "@tanstack/react-query";
import PostCard from "../../../components/PostCard";
import LoadingSpinner from "../../../components/LoadingSpinner";

function HomeBlog() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const breakpoints = {
    1400: {
      spaceBetween: 30,
      slidesPerView: 4,
    },
    900: {
      slidesPerView: 3,
    },
    550: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  };

  const { data: posts } = useQuery({
    queryKey: ["carrosel"],
    queryFn: () => postServices.getAllPosts(),
    stale: 18000000, //5h,
    refetchOnWindowFocus: false,
  });

  return (
    <section>
      <div className="homeBlog">
        <VisibilitySensor
          onChange={onChange}
          partialVisibility
          minTopValue={200}
        >
          <div>
            <div className="homeBlogTitle">
              <h1 className={`${isVisible ? "visible" : ""}`}>
                Explore nosso <span>blog</span>
              </h1>
              <p className={`${isVisible ? "visible" : ""}`}>
                O nosso universo de conhecimento está disponível na Plataforma de Conteúdo da GH! Nosso blog 
                oferece insights, dicas e tendências do setor logístico, mantendo você atualizado e informado. 
                Visite nosso blog e mergulhe no mundo da logística com a GH!
              </p>
            </div>

            <div className="homeBlog2">
              {posts?.data ? (
                <Swiper
                  slidesPerView={4}
                  spaceBetween={10}
                  breakpoints={breakpoints}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  modules={[Pagination, Navigation]}
                  className={`${isVisible ? "visible" : ""}`}
                >                 
                  <div className="swiper-button-prev"><img src={arrowLeft} width={'35px'} alt="" /></div>
                  {posts?.data?.map((post, i) => (
                    <SwiperSlide key={`post-${i}-id-${post.id}`}>
                      <PostCard post={{ ...post, isVisible }} />
                    </SwiperSlide>
                  ))}
                  <div className="swiper-button-next"><img src={arrowRight} width={'35px'} alt="" /></div>
                </Swiper>
              ) : (
                <LoadingSpinner />
              )}
            </div>
          </div>
        </VisibilitySensor>
      </div>
    </section>
  );
}

export default HomeBlog;
