import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/services/servicesOne/img-1.webp";
import logo1 from "../../../assets/img/services/servicesOne/card1.webp";
import logo2 from "../../../assets/img/services/servicesOne/card2.webp";
import logo3 from "../../../assets/img/services/servicesOne/card3.webp";
import logo4 from "../../../assets/img/services/servicesOne/card4.webp";

function ServicesOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div className="servicesOne" id="services">
        <div className="servicesOneText">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Soluções logísticas <br /> <span>abrangentes</span>
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Como um operador logístico de destaque, nossa empresa oferece
            serviços completos de armazenagem e transporte lotação.
            Comprometidos com a excelência, fornecemos soluções confiáveis e
            eficientes para atender às necessidades dos nossos clientes. Sendo
            um operador logístico de confiança, nossa equipe capacitada e
            infraestrutura moderna garantem a segurança e a integridade dos bens
            confiados a nós. Seja para pequenos ou grandes volumes, estamos
            preparados para oferecer um serviço de qualidade que supere as
            expectativas.
          </p>
        </div>
        <div className="servicesOneImg">
          <img className={`${isVisible ? "visible" : ""}`} src={img1} alt="" />
        </div>
        <div className="servicesOneB" id="services">
            <div className="cards">
              <div className="card1 card">
                <img src={logo1} alt="" />
                <p>
                  O SASSMAQ é uma certificação, sendo destinada aos
                  transportadores de produtos químicos, onde somos certificados
                  desde 2016.
                </p>
              </div>
              <div className="card2 card">
                <img src={logo2} alt="" />
                <p>
                  Desde 2016, nossa dedicação à excelência nos levou à
                  certificação na NBR ISO 9001, uma norma que enfatiza o Sistema
                  de Gestão da Qualidade, priorizando o foco no cliente.
                </p>
              </div>
              <div className="card3 card">
                <img src={logo3} alt="" />
                <p>
                  Em 2018, demos um passo crucial ao implementar a NBR ISO
                  14001, concentrando nossos esforços no Sistema de Gestão
                  Ambiental para promover práticas sustentáveis.
                </p>
              </div>
              <div className="card4 card">
                <img src={logo4} alt="" />
                <p>
                  Impulsionados pelos avanços conquistados com a NBR ISO 14001,
                  ainda em 2018, adotamos a ISO 45001 para fortalecer nosso
                  compromisso com a saúde e segurança ocupacional de nossa
                  equipe.
                </p>
              </div>
            </div>
          </div>
      </div>
    </VisibilitySensor>
  );
}

export default ServicesOne;
