import NavbarWhite from "../../components/NavbarWhite";
import Footer from "../../components/Footer";
import MediaButtons from "../../components/MediaButtons";
import ButtonTop from "../../components/ButtonTop";
import CookiesAccept from "../../components/CookiesAccept";

import WarehouseOne from "./components/WarehouseOne";
import WarehouseTwo from "./components/WarehouseTwo";
import WarehouseThree from "./components/WarehouseThree";
import WarehouseFour from "./components/WarehouseFour";
import WarehouseFive from "./components/WarehouseFive";
import WarehouseSix from "./components/WarehouseSix";
import WarehouseSeven from "./components/WarehouseSeven";
import WarehouseEight from "./components/WarehouseEight";
import WarehouseNine from "./components/WarehouseNine";
import WarehouseTen from "./components/WarehouseTen";
import WarehouseEleven from "./components/WarehouseEleven";

function WarehouseStructure() {
  return (
    <div id="warehouseStructure">
      <NavbarWhite></NavbarWhite>
      <ButtonTop></ButtonTop>
      <MediaButtons></MediaButtons>
      <CookiesAccept></CookiesAccept>
      <WarehouseOne></WarehouseOne>
      <WarehouseTwo></WarehouseTwo>
      <WarehouseTen></WarehouseTen>
      <WarehouseNine></WarehouseNine>
      <WarehouseThree></WarehouseThree>
      <WarehouseFour></WarehouseFour>
      <WarehouseFive></WarehouseFive>
      <WarehouseSix></WarehouseSix>
      <WarehouseEleven></WarehouseEleven>
      <WarehouseEight></WarehouseEight>
      <WarehouseSeven></WarehouseSeven>
      <Footer></Footer>
    </div>
  );
}

export default WarehouseStructure;
