import { generateClientErrorMessage } from "../utils/generateClientErrorMessage"
import { api } from "../config/api"

const getPostImage = async (path) => {
    try {
        const res = await api.get(`/getImage?path=${path}`, {
            responseType: 'blob'
        })

        let file = null

        if (!res.data) {//caso dê erro, retorna o html de 404
            file = new Blob([res.response.data], { type: res.response.data.type })
        } else {
            file = new Blob([res.data], { type: res.data.type })
        }

        const url = URL.createObjectURL(file)

        return { success: true, data: { url, file } }


    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}

const getAllPosts = async (title = "") => {
    try {
        const res = await api.get(`/getAllPosts?title=${title}`)

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, data: res.data.posts }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}

const getPostById = async (id) => {
    try {
        const res = await api.get(`/getPostById?id=${id}`);

        return res.data.data
    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}

const getLastPost = async () => {
    try {
        const res = await api.get(`/getLastPost`);

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, data: res.data.post }

    } catch (error) {
        return { success: false, message: generateClientErrorMessage(error) }
    }
}

export default {
    getPostImage,
    getAllPosts,
    getPostById,
    getLastPost
}