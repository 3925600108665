import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/esg/esgNine/img1.webp";
import img2 from "../../../assets/img/esg/esgNine/img2.webp";
import arrow from "../../../assets/img/esg/esgNine/arrow2.webp";

function EsgNine() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div id="esgNine" className={`esgNine ${isVisible ? "visible" : ""}`}>
        <div className="esgNineText">
          <div className="social">
            {/* <h1 className={`${isVisible ? 'visible' : ''}`}>Sinerghia Social</h1> */}
          </div>
          <div className="politica">
            <h2 className={`${isVisible ? "visible" : ""}`}>
              Política do programa
            </h2>
            <p className={`${isVisible ? "visible" : ""}`}>
              A Política Social do Programa Sinerghia Social da GH tem como
              objetivo promover o desenvolvimento social e melhorar as condições
              de vida nas comunidades onde atuamos. Trabalhamos em parceria com
              líderes comunitários e ONGs, identificando necessidades
              específicas e desenvolvendo iniciativas adequadas. Investimos na
              educação e capacitação, garantindo acesso à educação de qualidade
              e fortalecendo habilidades para o mercado de trabalho. Valorizamos
              a inclusão social e a diversidade, combatendo a discriminação e
              proporcionando igualdade de oportunidades para todos. Priorizamos
              a saúde e o bem estar, investindo em saúde preventiva e melhorias
              sanitárias nas áreas em que operamos. Promovemos o desenvolvimento
              sustentável, implementando práticas socioambientais responsáveis e
              promovendo o uso consciente dos recursos naturais. Monitoramos e
              avaliamos regularmente nossas ações sociais, buscando sua
              efetividade e transparência. Essa política orienta nossas ações
              sociais e busca contribuir para uma sociedade mais justa e
              sustentável.
            </p>
          </div>
          <div className="links">
            <a
              href="#esgOne"
              reloadDocument
              className={`link1 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" />
              <p>Programa Mudando Histórias</p>
            </a>
            <a
              href="#esgSeven"
              reloadDocument
              className={`link2 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" />
              <p>Páscoa solidária</p>
            </a>
            <a
              href="#esgSeven"
              reloadDocument
              className={`link2 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" />
              <p>Programa Olho Vivo Na Estrada</p>
            </a>
            <a
              href="#esgSeven"
              reloadDocument
              className={`link2 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" />
              <p>Natal solidário</p>
            </a>
            <a
              href="#esgSeven"
              reloadDocument
              className={`link2 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" />
              <p>Programa Laço Amarelo</p>
            </a>
            <a
              href="#esgSeven"
              reloadDocument
              className={`link2 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" />
              <p>Heróis do cachecol</p>
            </a>
            <a
              href="#esgSeven"
              reloadDocument
              className={`link2 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" />
              <p>Programa Na Mão Certa</p>
            </a>
            <a
              href="#esgSeven"
              reloadDocument
              className={`link2 ${isVisible ? "visible" : ""}`}
            >
              <img src={arrow} alt="" />
              <p>Resinas que transformam</p>
            </a>
          </div>
        </div>
        <div className={`esgNineLogo ${isVisible ? "visible" : ""}`}>
          <img src={img1} alt="" width={"350px"} />
          <img src={img2} alt="" width={"350px"} />
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default EsgNine;
