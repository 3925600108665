import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/warehouseStructure/warehouseNine/img1.webp";
import img2 from "../../../assets/img/warehouseStructure/warehouseNine/img2.webp";
import img3 from "../../../assets/img/warehouseStructure/warehouseTen/img3.webp";

function WarehouseTen() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
      <div className={`warehouseTen ${isVisible ? "visible" : ""}`}>
        <div className="nineImg">
          <img
            className={`img2 ${isVisible ? "visible" : ""}`}
            src={img3}
            alt=""
          />
        </div>
        <div className="nineText">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            <span>Matriz Itajaí - SC</span>
            <br />A menos de trinta minutos dos principais portos da região
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Com 7.000m² de área e capacidade para 8.000 posições/pallets,
            localizado na rua Francisco Reis, 1402, bairro Cordeiros, em Itajaí,
            o nosso depósito Matriz é um espaço amplo e moderno, que foi
            cuidadosamente planejado para atender às necessidades logísticas com
            a máxima eficiência para cada cliente. Ele é equipado com tecnologia
            de ponta para o acompanhamento de processos, garantindo agilidade em
            todas as operações. Nossa equipe altamente treinada está sempre
            pronta para oferecer soluções logísticas personalizadas, assegurando
            que sua carga seja movimentada no menor tempo possível, sem
            comprometer a segurança e a integridade dos produtos.
          </p>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default WarehouseTen;
