import React from "react";
import SwiperCard from "./components/SwiperCard";
import Newsletter from "./components/Newsletter";

const AllBlogPosts = () => {
  return (
    <>
      <div className="blogTitle">
        <h1>Confira nossas <span>últimas publicações!</span></h1>
      </div>
      <SwiperCard />
      <Newsletter />
    </>
  );
};

export default React.memo(AllBlogPosts);
