import Routes from "./Routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
/* import { ReactQueryDevtools } from '@tanstack/react-query-devtools' */
import Alert from "./components/Alert";
import { AlertProvider } from "./contexts/AlertContext";

function App() {
  return (
    <QueryClientProvider client={new QueryClient()} >
      <AlertProvider>
        <Routes />
        <Alert />
      </AlertProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
