import React, { useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';

import VisibilitySensor from "react-visibility-sensor";

import 'swiper/css';
import 'swiper/css/navigation';

import {Autoplay} from "swiper/modules";

import img1 from '../../../assets/img/warehouseStructure/warehouseOne/img1.webp';
import img2 from '../../../assets/img/warehouseStructure/warehouseOne/img2.webp';
import img3 from '../../../assets/img/warehouseStructure/warehouseOne/img3.webp';
import img4 from '../../../assets/img/warehouseStructure/warehouseOne/img4.webp';
import img5 from '../../../assets/img/warehouseStructure/warehouseOne/img5.webp';
import img6 from '../../../assets/img/warehouseStructure/warehouseOne/img6.webp';
import img7 from '../../../assets/img/warehouseStructure/warehouseOne/img7.webp';
import imgBg from "../../../assets/img/home/welcome/bg.webp";

const breakpoints = {
  1000: {
    slidesPerView: 3,
  },
  700: {
    slidesPerView: 2,
  },
  0:{
    slidesPerView: 1,
  }
}

function WarehouseOne(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
            <div className="warehouseOne">
              <div className="warehouseOneTitle">
                <h1 className={`${isVisible ? 'visible' : ''}`}><span>Armazenagem</span></h1>
                <p className={`${isVisible ? 'visible' : ''}`}>
                  Na GH, oferecemos serviços de armazenagem confiáveis e seguros. Com instalações modernas e equipe especializada, 
                  garantimos que sua carga seja manuseada e armazenada com cuidado, proporcionando eficiência em cada etapa do processo. 
                  Conte conosco para armazenar sua carga com qualidade e segurança.
                </p>
              </div>
              <Swiper 
                className={`${isVisible ? 'visible' : ''}`}
                modules={[Autoplay]}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={breakpoints}
                keyboard={true}
                spaceBetween={10}
                speed={700}
                slidesPerView={'3'}
              >
                <SwiperSlide>
                    <div className={`card card1`}>
                        <img src={img1} alt="" width={'400px'}/>
                        <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`card card2`}>
                        <img src={img2} alt="" width={'400px'}/>
                        <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`card card3`}>
                        <img src={img3} alt="" width={'400px'}/>
                        <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`card card4`}>
                        <img src={img4} alt="" width={'400px'}/>
                        <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`card card5`}>
                        <img src={img5} alt="" width={'400px'}/>
                        <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`card card6`}>
                        <img src={img6} alt="" width={'400px'}/>
                        <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`card card7`}>
                        <img src={img7} alt="" width={'400px'}/>
                        <img src={imgBg} className="imgBg" alt="" width={'500px'}/>
                    </div>
                </SwiperSlide>
              </Swiper>
            </div>
        </VisibilitySensor>
    )
}
export default WarehouseOne;