import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

function CareerThree() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div className={`careerThree ${isVisible ? "visible" : ""}`}>
        <h1 className={`${isVisible ? "visible" : ""}`}>
          Faça parte da nossa equipe!
        </h1>
        <p className={`${isVisible ? "visible" : ""}`}>
          Estamos sempre buscando novos talentos para somar ao nosso time de
          profissionais. Clique no botão abaixo para acessar nossa plataforma de
          seleção, consultar as vagas em aberto e enviar seu currículo.
        </p>
        <a
          className={`${isVisible ? "visible" : ""}`}
          href="https://ghlogistica.vagas.solides.com.br/"
          target="_blank"
          rel="noreferrer"
        >
          <button>Ver vagas em aberto</button>
        </a>
      </div>
    </VisibilitySensor>
  );
}
export default CareerThree;
