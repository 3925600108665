import React, { useState, useEffect } from "react";
import {
  CNavbar,
  CNavbarBrand,
  CContainer,
  CNavbarToggler,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
  CNavbarNav,
  CNavItem,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react-pro";

import logo from "../assets/icon/logo.png";

function Navbar() {
  const [visible, setVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CNavbar
      className={`py-4 ${scrolled ? "scrolled" : ""}`}
      expand="xl"
      translate="no"
    >
      <div className="nav-container">
        <CContainer fluid>
          <CNavbarBrand className={`navLogo2`}>
            <a href="/">
              <img src={logo} alt="logo" width={200} />
            </a>
          </CNavbarBrand>
          <CNavbarToggler
            aria-controls="offcanvasNavbar2"
            aria-label="Toggle navigation"
            onClick={() => setVisible(!visible)}
          />
          <COffcanvas
            id="offcanvasNavbar2"
            placement="end"
            portal={false}
            visible={visible}
            onHide={() => setVisible(false)}
          >
            <COffcanvasHeader>
              <COffcanvasTitle></COffcanvasTitle>
              <CCloseButton
                className="text-reset"
                onClick={() => setVisible(false)}
              />
            </COffcanvasHeader>
            <COffcanvasBody>
              <div className="flex-content-nav">
                <CNavbarNav>
                  <CNavItem className={`px-1 homeLink`}>
                    <a href="/home" active reloadDocument>
                      Início
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 aboutLink`}>
                    <a href="/about" active reloadDocument>
                      Sobre
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 servicesLink`}>
                    <a href="/services" active reloadDocument>
                      Soluções
                    </a>
                  </CNavItem>
                  <CDropdown
                    variant="btn-group"
                    className={`px-1 structureLink`}
                  >
                    <CDropdownToggle>Estrutura</CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem>
                        <a href="/warehouseStructure" active reloadDocument>
                          Armazém
                        </a>
                      </CDropdownItem>
                      <CDropdownItem>
                        <a href="/fleetStructure" active reloadDocument>
                          Frota
                        </a>
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </CNavbarNav>
                <CNavbarBrand className={`navLogo`}>
                  <img src={logo} alt="logo" width={230} />
                </CNavbarBrand>
                <CNavbarNav>
                  <CNavItem className={`px-1 careerLink`}>
                    <a href="/career" active reloadDocument>
                      #CarreirasGH
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 contactLink`}>
                    <a href="/contact" active reloadDocument>
                      Contato
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 esgLink`}>
                    <a href="/esg" active reloadDocument>
                      ESG
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 blogLink`}>
                    <a href="/blog" active reloadDocument>
                      Blog
                    </a>
                  </CNavItem>
                  <CDropdown variant="btn-group" className="portalLink">
                    <CDropdownToggle>Portais</CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem href="http://portal.ghlogistica.com.br:8081/view/rastreamento/RastreioPedidoSimplesTransporte.php">
                        Rastreio de carga
                      </CDropdownItem>
                      <CDropdownItem href="http://portal.ghlogistica.com.br:8081/view/estrutura/login.php">
                        Portal do cliente
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </CNavbarNav>
              </div>
            </COffcanvasBody>
          </COffcanvas>
        </CContainer>
      </div>
    </CNavbar>
  );
}

export default Navbar;
