import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../assets/img/about/aboutEight/img1.webp";
import img2 from "../../../assets/img/about/aboutEight/img2.webp";
import img3 from "../../../assets/img/about/aboutEight/img3.webp";
import img4 from "../../../assets/img/about/aboutEight/img4.webp";

function AboutEight(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
      if (visibility) {
        setIsVisible(true);
      }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="aboutEight">
            <div className="aboutEightTitle">
                <h1 className={`${isVisible ? 'visible' : ''}`}>Mudamos o <span>conceito</span>, mas não a <span>essência</span></h1>
                <p className={`${isVisible ? 'visible' : ''}`}>
                    Nosso conceito impulsiona a logística, conectando mercados, indústrias e pessoas para que negócios alcancem seus destinos. 
                    Na GH, que significa Growth e Humanity (Crescimento e Humanidade), nosso modelo de negócios é centrado nas pessoas, valorizando 
                    conexões genuínas e impactando vidas positivamente. Para nós, o verdadeiro sucesso vai além do lucro, sendo medido pelo bem que fazemos. 
                    Guiados por sustentabilidade, justiça e governança, estamos comprometidos em construir um futuro mais equitativo e inclusivo, propagando 
                    esses princípios no mundo dos negócios.
                </p>
                <p className={`${isVisible ? 'visible' : ''}`}>
                    Investimos no desenvolvimento profissional de nossa equipe, oferecendo treinamentos e oportunidades de avanço para que cresçam junto com a empresa. 
                    A excelência operacional é um pilar fundamental para nós, garantindo que cada operação e serviço seja realizado com qualidade e eficiência. Nosso 
                    compromisso com ESG (ambiental, social e governança) reflete-se em práticas sustentáveis e projetos que beneficiam a comunidade e o meio ambiente.
                </p>
                <p className={`${isVisible ? 'visible' : ''}`}>
                    Para nossos parceiros, a GH é mais que uma empresa logística; é um aliado confiável que oferece segurança e eficiência em cada operação. 
                    Para as empresas que confiam em nós, somos sinônimo de expertise e soluções otimizadas. E para nossos colaboradores, somos uma família dedicada a 
                    conectar, entregar e solucionar, enfrentando cada desafio com união e paixão.
                </p>
            </div>
            <div className="aboutEightImg">
                <img className={`img1 ${isVisible ? 'visible' : ''}`} src={img1} alt="" width={'600px'} />
                <img className={`img2 ${isVisible ? 'visible' : ''}`} src={img2} alt="" width={'600px'} />
                <img className={`img3 ${isVisible ? 'visible' : ''}`} src={img3} alt="" width={'600px'} />
                <img className={`img4 ${isVisible ? 'visible' : ''}`} src={img4} alt="" width={'600px'} />
            </div>
        </div>
        </VisibilitySensor>
    )
}

export default AboutEight;