import IconSuccess from '../assets/icon/sucesso.png'
import IconDanger from '../assets/icon/erro.png'
import Iconwarning from '../assets/icon/aviso.png'
import { useAlert } from '../contexts/AlertContext'
import React from 'react'
import { CAlert } from '@coreui/react-pro'
import style from '../assets/scss/components/alert.module.scss'

const Alert = () => {
  const { alertData, hideAlert } = useAlert()


  if (!alertData || !alertData?.message?.trim()) {
    return null
  }

  return (
    <div className={style.alertModal}>
      <CAlert
        color={
          alertData?.type === 'success'
            ? 'success'
            : alertData?.type === 'error'
              ? 'danger'
              : 'warning'
        }
        className={
          alertData?.type === 'success'
            ? 'message-success'
            : alertData?.type === 'error'
              ? 'message-danger'
              : 'message-warning'
        }
        dismissible
        onClose={hideAlert}
      >
        <img
          src={
            alertData?.type === 'success'
              ? IconSuccess
              : alertData?.type === 'error'
                ? IconDanger
                : Iconwarning
          }
          alt={alertData?.message}
          width={30}
        />
        {alertData?.message}
      </CAlert>
    </div>
  )
}

export default React.memo(Alert)
