import axios from 'axios'

const config = {
  baseURL: 'https://emailsender.singlec.com.br/api/',
}

const axiosConfig = axios.create(config)

axiosConfig.defaults.headers['Content-Type'] = 'application/json'

axiosConfig.interceptors.response.use(response => {
  return response;
}, error => {
  console.log(error.message)
});

export const emailApi = axiosConfig