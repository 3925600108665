import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import card1 from "../../../assets/img/services/servicesTwo/card1.webp";
import card2 from "../../../assets/img/services/servicesTwo/card3.webp";
import card3 from "../../../assets/img/services/servicesTwo/card2.webp";
import card4 from "../../../assets/img/services/servicesTwo/card4.webp";
import card5 from "../../../assets/img/services/servicesTwo/card5.webp";

function ServicesTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <div className="servicesTwo">
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={340}>
        <div>
          <div className="servicesTwoText">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              Transporte Rodoviário
            </h1>
            <h2 className={`${isVisible ? "visible" : ""}`}>
              O melhor caminho para sua carga
            </h2>
            <p className={`${isVisible ? "visible" : ""}`}>
              Nossa frota abrangente não apenas oferece uma variedade de
              veículos, mas também incorpora tecnologias de ponta para garantir
              a eficiência e segurança. Além disso, nosso Gerenciamento de Risco
              dedicado assegura suporte contínuo, desde o início até a entrega,
              adaptando-se às necessidades específicas de cada viagem, para
              proporcionar soluções logísticas sob medida e confiáveis.
            </p>
          </div>
          <div className={`servicesTwoCards-bg ${isVisible ? "visible" : ""}`}>
            <div className="servicesTwoCards">
              <div className={`card card1 ${isVisible ? "visible" : ""}`}>
                <img
                  className={`${isVisible ? "visible" : ""}`}
                  src={card1}
                  alt=""
                  width={"280px"}
                />
                <h3 className={`${isVisible ? "visible" : ""}`}>
                  Cargas Palletizadas
                </h3>
                <p className={`${isVisible ? "visible" : ""}`}>
                  Sider é um tipo de caminhão versátil e conhecido por
                  transportar grandes quantidades de cargas palletizadas.
                </p>
              </div>
              <div className={`card card2 ${isVisible ? "visible" : ""}`}>
                <img
                  className={`${isVisible ? "visible" : ""}`}
                  src={card2}
                  alt=""
                  width={"280px"}
                />
                <h3 className={`${isVisible ? "visible" : ""}`}>
                  Grandes Formatos
                </h3>
                <p className={`${isVisible ? "visible" : ""}`}>
                  Por ser aberta, o seu uso mais comum é para fazer o transporte
                  de mercadorias mais resistentes.
                </p>
              </div>
              <div className={`card card3 ${isVisible ? "visible" : ""}`}>
                <img
                  className={`${isVisible ? "visible" : ""}`}
                  src={card4}
                  alt=""
                  width={"280px"}
                />
                <h3 className={`${isVisible ? "visible" : ""}`}>
                  Cargas a Granel
                </h3>
                <p className={`${isVisible ? "visible" : ""}`}>
                  Os silos ou granadeiros, como também são conhecidos, são
                  feitos para fazer o transporte de grãos.
                </p>
              </div>
              <div className={`card card4 ${isVisible ? "visible" : ""}`}>
                <img
                  className={`${isVisible ? "visible" : ""}`}
                  src={card3}
                  alt=""
                  width={"280px"}
                />
                <h3 className={`${isVisible ? "visible" : ""}`}>
                  Volume em dobro
                </h3>
                <p className={`${isVisible ? "visible" : ""}`}>
                  É um tipo de caminhão que se enquadra na categoria de
                  combinação veicular rodoviária e são utilizados para
                  transportar grandes quantidades de cargas.
                </p>
              </div>
              <div className={`card card5 ${isVisible ? "visible" : ""}`}>
                <img
                  className={`${isVisible ? "visible" : ""}`}
                  src={card1}
                  alt=""
                  width={"280px"}
                />
                <h3 className={`${isVisible ? "visible" : ""}`}>
                  Cargas Portuárias
                </h3>
                <p className={`${isVisible ? "visible" : ""}`}>
                  Utilizado em zonas portuárias para descarregar e carregar
                  contêineres.
                </p>
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
}

export default ServicesTwo;
